.add-class {
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 10px 0;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .add-class-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: #f4f4f4;
    padding: 10px;
    border-radius: 4px;
  }
  
  .add-class-header h3 {
    margin: 0;
  }
  
  .expand-button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  
  .add-class-content {
    padding: 10px;
  }
  
  .add-class-content input {
    display: block;
    width: calc(100% - 20px);
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .add-class-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  