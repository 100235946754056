.table-title {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700;
    background: #204b99; /* Background color */
    color: #fff;
    /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease; */
}

/* .table-title:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
} */
