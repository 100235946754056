/* Table CSS */


/* h1{
    font-size: 30px;
    color: #ffd700;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 15px;
  } */

.live-match-list-root {
    background-image: url('/src/assets/stadium_wallpaper.jpg');
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
}

.manage-master-downline-root {
    /* Cric247 changes earlier below -
    background-image: url('/src/assets/sport_background_blue.jpg');
    background-size: cover;
    background-repeat: no-repeat; */
    height: 72vh;
}

.statement-root {
    background-image: url('/src/assets/bat_wicket.jpg');
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    padding-left: 2px;
}

.cric-statement-root {
    height: 100%;
    padding-left: 3px;
    margin-top: 71px;
}

.past-match-list-root {
    background-image: url('/src/assets/bat_wicket.jpg');
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
}

.upcoming-match-list-root {
    background-image: url('/src/assets/bat_wicket.jpg');
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
}

.table-top-container {
    width: 100%;
    padding-top: 0px;
    /* Cric247 changes earlier 110px */
    margin-bottom: 0px;
    background: white;
    padding-bottom: 50px;
}

.table-top-container-commission-limit {
    width: 100%;
    padding-top: 0px;
    /* Cric247 changes earlier 110px */
    margin-bottom: 2px;
}

.add-master-container {
    width: 100%;
    /* Cric247 changes earlier 5% */
    text-align: right;
    /* position: fixed; */
    margin-top: 2px;
}

.table-pagination-container {
    width: 100%;
    text-align: right;
    margin-bottom: 5px;
    position: fixed;
}

.table-pagination-container-child {
    width: fit-content;
    margin: auto;
}

.add-games-container {
    width: 100%;
    padding-top: 5%;
    text-align: right;
    padding-right: 11.4%;
    margin-bottom: 5px;
    position: fixed;
}

.add-games-container-upcoming {
    width: 100%;
    padding-top: 5%;
    text-align: right;
    padding-right: 14%;
    margin-bottom: 5px;
    position: fixed;
}

.add-games-container-past {
    width: 100%;
    padding-top: 5%;
    text-align: right;
    padding-right: 8.3%;
    margin-bottom: 5px;
    position: fixed;
}

.table-container {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    /* background: white; */
    /* -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3); */
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    z-index: -10;
    margin-top: 5px;
    /* background: #f8f8f8; */
}

table {
    width: 100%;
    table-layout: absolute;
}

.tbl-header {
    background-color: rgba(0, 0, 0, 0.9);
    background: #E5E4E2;
    border-bottom: none;
    color: #011d36;
}

.master-list-title-th-username {
    min-width: 160px;
    border: 1px solid #C0C0C0;
    text-align: left;
    padding-left: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.master-list-title-th-stmt-long {
    min-width: 170px;
    border: 1px solid #C0C0C0;
    text-align: left;
    padding-left: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.master-list-title-td-username {
    min-width: 160px;
    border: 1px solid #C0C0C0;
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: white;
}

.master-list-title-td-stmt-long {
    min-width: 170px;
    border: 1px solid #C0C0C0;
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: white;
}

.master-list-title-td-game-title {
    min-width: 240px;
    border: 1px solid #C0C0C0;
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: white;
}

.master-list-title-th-game-title {
    min-width: 240px;
    border: 1px solid #C0C0C0;
    text-align: left;
    padding-left: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.master-list-title-td-past-game-title {
    min-width: 260px;
    border: 1px solid #C0C0C0;
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: white;
}

.master-list-title-td-match-list-title {
    min-width: 380px;
    border: 1px solid #C0C0C0;
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: white;
}

.master-list-title-th-past-game-title {
    min-width: 260px;
    border: 1px solid #C0C0C0;
    text-align: left;
    padding-left: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.master-list-title-th-match-list-title {
    min-width: 380px;
    border: 1px solid #C0C0C0;
    text-align: left;
    padding-left: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.master-list-title-th-match-list-datetime {
    min-width: 220px;
    border: 1px solid #C0C0C0;
    text-align: left;
    padding-left: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.master-list-title-th {
    min-width: 115px;
    border: 1px solid #C0C0C0;
    text-align: left;
    padding-left: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.master-list-title-td {
    min-width: 115px;
    border: 1px solid #C0C0C0;
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: white;
}

.master-list-title-td-match-list-datetime {
    min-width: 220px;
    border: 1px solid #C0C0C0;
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: white;
}

.master-list-title-td-blue {
    min-width: 115px;
    border: 1px solid #C0C0C0;
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: white;
    color: #034694;
}

.master-list-title-td-red {
    min-width: 115px;
    border: 1px solid #C0C0C0;
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: white;
    color: red;
}

.master-list-title-td-green {
    min-width: 115px;
    border: 1px solid #C0C0C0;
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: white;
    color: green;
}

.master-list-title-actions-th {
    min-width: 250px;
    border: 1px solid #C0C0C0;
    text-align: left;
    padding-left: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.master-list-title-actions-td {
    min-width: 250px;
    border: 1px solid #C0C0C0;
    text-align: left;
    padding-left: 4px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: white;
}

.master-list-title-actions-th-small {
    min-width: 200px;
    border: 1px solid #C0C0C0;
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.master-list-title-actions-td-small {
    min-width: 200px;
    border: 1px solid #C0C0C0;
    text-align: left;
    padding-left: 4px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: white;
}

.tbl-content {
    height: 425px;
    overflow-x: auto;
    margin-top: 0px;
}

.tbl-content-live-matches {
    height: 50px;
    overflow-x: auto;
    margin-top: 0px;
}

.label-status-active {
    font-size: 12px;
    background: #e8e8e8;
    color: green;
    border-radius: 5px;
    padding: 2px;
    padding-left: 5px;
    padding-right: 5px;
    font-style: thin;
}

.label-status-blocked {
    font-size: 12px;
    background: #e8e8e8;
    color: red;
    border-radius: 5px;
    padding: 2px;
    padding-left: 5px;
    padding-right: 5px;
}

.label-status-new {
    font-size: 12px;
    background: #e8e8e8;
    color: blue;
    border-radius: 5px;
    padding: 2px;
    padding-left: 5px;
    padding-right: 5px;
}

.label-tag-sst {
    font-size: 12px;
    background: #e8e8e8;
    color: #FF028D;
    border-radius: 5px;
    padding: 2px;
    padding-left: 5px;
    padding-right: 5px;
    font-style: thin;
}

.label-tag-st {
    font-size: 12px;
    background: #e8e8e8;
    color: #0165FC;
    border-radius: 5px;
    padding: 2px;
    padding-left: 5px;
    padding-right: 5px;
    font-style: thin;
    margin-right: 6px;
}

.label-tag-agent {
    font-size: 12px;
    background: #e8e8e8;
    color: #f43605;
    border-radius: 5px;
    padding: 2px;
    padding-left: 5px;
    padding-right: 5px;
    font-style: thin;
    margin-right: 16px;
}

.label-tag-user {
    font-size: 12px;
    background: #e8e8e8;
    color: #7a1e8a;
    border-radius: 5px;
    padding: 2px;
    padding-left: 5px;
    padding-right: 5px;
    font-style: thin;
    margin-right: 36px;
}

.tbl-content-light {
    height: 382px;
    overflow-x: auto;
    margin-top: 0px;
    border: 1px solid #011d36;
    color: #011d36;
}

.mast-dwln-th-center {
    min-width: 100px;
    padding: 10px 20px;
    padding-right: 5px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    color: #ffd700;
    text-transform: uppercase;
}

.mast-dwln-th-center-rest {
    min-width: 100px;
    padding: 10px 10px;
    padding-left: 0px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    color: #ffd700;
    text-transform: uppercase;
}

.mast-dwln-th-left {
    min-width: 100px;
    padding: 10px 15px;
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    color: #ffd700;
    text-transform: uppercase;
}

.mast-dwln-th-right {
    min-width: 70px;
    padding: 10px 10px;
    padding-left: 0px;
    text-align: right;
    font-weight: 500;
    font-size: 14px;
    color: #ffd700;
    text-transform: uppercase;
}

.mast-dwln-td-left {
    min-width: 120px;
    padding-top: 10px;
    padding-left: 10px;
    text-align: left;
    vertical-align: middle;
    font-size: 14px;
    /* border: 1px solid; */
    /* border-bottom: solid 1px rgba(255,255,255,0.1); */
}

.mast-dwln-td-center {
    min-width: 90px;
    padding-top: 5px;
    text-align: center;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    font-size: 14px;
    /* border: 1px solid; */
    /* border-bottom: solid 1px rgba(255,255,255,0.1); */
}

.mast-dwln-td-right-red {
    min-width: 75px;
    padding-top: 5px;
    text-align: right;
    vertical-align: middle;
    font-size: 14px;
    color: red;
    /* border: 1px solid; */
    /* border-bottom: solid 1px rgba(255,255,255,0.1); */
}

.mast-dwln-td-right-green {
    min-width: 75px;
    padding-top: 5px;
    text-align: right;
    vertical-align: middle;
    font-size: 14px;
    color: green;
    /* border: 1px solid; */
    /* border-bottom: solid 1px rgba(255,255,255,0.1); */
}

.mast-dwln-td-center-red {
    min-width: 80px;
    padding-top: 5px;
    text-align: center;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    font-size: 14px;
    color: red;
    /* border: 1px solid; */
    /* border-bottom: solid 1px rgba(255,255,255,0.1); */
}

.mast-dwln-td-center-green {
    min-width: 80px;
    padding-top: 5px;
    text-align: center;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    font-size: 14px;
    color: green;
    /* border: 1px solid; */
    /* border-bottom: solid 1px rgba(255,255,255,0.1); */
}

.mast-dwln-td-center-purple {
    min-width: 80px;
    padding-top: 5px;
    text-align: center;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    font-size: 14px;
    color: purple;
    /* border: 1px solid; */
    /* border-bottom: solid 1px rgba(255,255,255,0.1); */
}

.blink-text {
    margin-left: 10px;
    animation: blink-animation 2s steps(5, start) infinite;
    -webkit-animation: blink-animation 2s steps(5, start) infinite;
}

.datepicker-top-container {
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.datepicker-calendar-container {
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    padding-right: 5px;
}

.label-datepicker {
    font-size: 16px;
    color: white;
    margin-left: 5px;
    margin-right: 5px;
    font-weight: bold;
}

@keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

@-webkit-keyframes blink-animation {
    to {
        visibility: hidden;
    }
}


/* demo styles */

/* @import url(https://fonts.googleapis.com/css?family=Roboto:400,500,300,700); */
body {
    margin: 0px;
    background: -webkit-linear-gradient(left, #fffceb, #fffceb);
    background: linear-gradient(to right, #fffceb, #fffceb);
    font-family: 'Roboto', sans-serif;
}

section {
    margin: 50px;
}


/* follow me template */
.made-with-love {
    margin-top: 40px;
    padding: 10px;
    clear: left;
    text-align: center;
    font-size: 10px;
    font-family: arial;
    color: #fff;
}

.made-with-love i {
    font-style: normal;
    color: #F50057;
    font-size: 14px;
    position: relative;
    top: 2px;
}

.made-with-love a {
    color: #fff;
    text-decoration: none;
}

.made-with-love a:hover {
    text-decoration: underline;
}


/* for custom scrollbar for webkit browser*/

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.mast-dwln-dot-red {
    height: 12px;
    width: 12px;
    margin-left: 10px;
    background-color: red;
    border-radius: 50%;
}

.mast-dwln-dot-green {
    height: 12px;
    width: 12px;
    margin-left: 10px;
    background-color: green;
    border-radius: 50%;
}

.mast-dwln-dot-purple {
    height: 12px;
    width: 12px;
    margin-left: 10px;
    background-color: purple;
    border-radius: 50%;
}

.date-range-presets-picker-container {
    text-align: left;
    margin-left: 13px;
    /* background: orange; */
}

.entity-ledger-root {
    width: 99.4%;
    margin-left: 3px;
}

@media (orientation: portrait) {

    .live-match-list-root {
        position: absolute;
    }

    .manage-master-downline-root {
        /* Cric247 changes earlier as below
        position: absolute; */
        height: 63vh;
    }

    .statement-root {
        position: absolute;
    }

    .cric-statement-root {
        height: 100%;
        padding-top: 0px;
        margin-left: 0px;
        margin-top: -14px;
    }

    .past-match-list-root {
        position: absolute;
    }

    .upcoming-match-list-root {
        position: absolute;
    }

    .add-master-container {
        width: 100%;
        padding-top: 0px;
        /* Cric247 changes earlier 70px */
        text-align: right;
        /* position: fixed;
        overflow: hidden; */
    }

    .add-games-container {
        width: 100%;
        padding-top: 70px;
        text-align: right;
        padding-right: 0px;
        margin-bottom: 5px;
        position: fixed;
    }

    .add-games-container-upcoming {
        width: 100%;
        padding-top: 18%;
        text-align: right;
        padding-right: 0%;
        margin-bottom: 5px;
        position: fixed;
    }

    .add-games-container-past {
        width: 100%;
        padding-top: 18%;
        text-align: right;
        padding-right: 0%;
        margin-bottom: 5px;
        position: fixed;
    }

    .tbl-content {
        height: 430px;
        overflow-x: auto;
        margin-top: 0px;
    }

    .tbl-content-live-matches {
        height: 50px;
        overflow-x: auto;
        margin-top: 0px;
    }

    .mast-dwln-th-center {
        min-width: 50px;
        padding: 10px 20px;
        padding-right: 5px;
        text-align: center;
        font-weight: 500;
        font-size: 10px;
        color: #ffd700;
        text-transform: uppercase;
    }

    .mast-dwln-th-center-rest {
        min-width: 45px;
        padding: 10px 10px;
        padding-left: 0px;
        text-align: center;
        font-weight: 500;
        font-size: 10px;
        color: #ffd700;
        text-transform: uppercase;
    }

    .mast-dwln-th-left {
        min-width: 50px;
        padding: 10px 10px;
        text-align: left;
        font-weight: 500;
        font-size: 10px;
        color: #ffd700;
        text-transform: uppercase;
    }

    .mast-dwln-th-right {
        min-width: 35px;
        padding: 10px 10px;
        padding-left: 0px;
        text-align: right;
        font-weight: 500;
        font-size: 10px;
        color: #ffd700;
        text-transform: uppercase;
    }

    .mast-dwln-td-left {
        min-width: 85px;
        padding-top: 12px;
        padding-left: 5px;
        text-align: left;
        vertical-align: middle;
        font-size: 10px;
        /* border: 1px solid; */
        /* border-bottom: solid 1px rgba(255,255,255,0.1); */
    }

    .mast-dwln-td-center {
        min-width: 60px;
        padding-top: 5px;
        text-align: center;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        font-size: 10px;
        /* border: 1px solid; */
        /* border-bottom: solid 1px rgba(255,255,255,0.1); */
    }

    .mast-dwln-td-right-red {
        min-width: 45px;
        padding-top: 5px;
        text-align: right;
        vertical-align: middle;
        font-size: 10px;
        color: red;
        /* border: 1px solid; */
        /* border-bottom: solid 1px rgba(255,255,255,0.1); */
    }

    .mast-dwln-td-right-green {
        min-width: 45px;
        padding-top: 5px;
        text-align: right;
        vertical-align: middle;
        font-size: 10px;
        color: green;
        /* border: 1px solid; */
        /* border-bottom: solid 1px rgba(255,255,255,0.1); */
    }

    .mast-dwln-td-center-red {
        min-width: 50px;
        padding-top: 5px;
        text-align: center;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        font-size: 10px;
        color: red;
        /* border: 1px solid; */
        /* border-bottom: solid 1px rgba(255,255,255,0.1); */
    }

    .mast-dwln-td-center-green {
        min-width: 50px;
        padding-top: 5px;
        text-align: center;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        font-size: 10px;
        color: green;
        /* border: 1px solid; */
        /* border-bottom: solid 1px rgba(255,255,255,0.1); */
    }

    .mast-dwln-td-center-purple {
        min-width: 50px;
        padding-top: 5px;
        text-align: center;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        font-size: 10px;
        color: purple;
        /* border: 1px solid; */
        /* border-bottom: solid 1px rgba(255,255,255,0.1); */
    }

    .mast-dwln-dot-red {
        height: 8px;
        width: 8px;
        margin-left: 3px;
        margin-bottom: 1px;
        background-color: red;
        border-radius: 50%;
    }

    .mast-dwln-dot-green {
        height: 8px;
        width: 8px;
        margin-left: 3px;
        margin-bottom: 1px;
        background-color: green;
        border-radius: 50%;
    }

    .mast-dwln-dot-purple {
        height: 8px;
        width: 8px;
        margin-left: 3px;
        margin-bottom: 1px;
        background-color: purple;
        border-radius: 50%;
    }

    .date-range-presets-picker-container {
        text-align: left;
        margin-left: 10px;
        /* position: fixed;
        z-index: 999; */
    }

    .table-top-container {
        width: 100%;
        padding-top: 0px;
        /* Cric247 changes earlier 110px */
        margin-bottom: 0px;
    }

    .table-top-container-commission-limit {
        width: 100%;
        padding-top: 0px;
        /* Cric247 changes earlier 110px */
        margin-bottom: 2px;
    }

    .table-container {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        /* background: white; */
        /* -moz-border-radius: 2px;
        -webkit-border-radius: 2px;
        border-radius: 2px;
        -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3); */
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        z-index: -10;
        margin-top: 40px;
        /* background: #f8f8f8; */
    }

    /* .entity-ledger-root {
        margin-left: 10px;
        margin-right: 10px;
    } */
}