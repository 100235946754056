.login-root {
    background-image: url('/src/assets/login_background.jpg');
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
}

.login-page {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.login-card {
    display: flex;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px rgba(154, 154, 154, 154);
    border-radius: 10px;
    overflow: hidden;
    /* To ensure the border-radius works correctly */
    justify-content: center;
    margin: 20px;
    width: 100%;
    max-width: 700px;
    height: 500px;
}

.left-side {
    flex: 1;
    /* background-image: url('../assets/play247login.jpg'); */
    background-image: url('../assets/parvatibook_crop.jpg');
    background-size: cover;
    /* Adjust as needed */
    background-position: center;
    /* Adjust as needed */

    width: 300px;
}

.right-side {
    flex: 1;
    padding: 10px;
    padding-top: 50px;
    justify-content: center;
    align-items: center;
    background-color: #060c11;
    /* background-image: url('../assets/play247login.jpg'); */
}

.auth-card-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-card-text {
    text-align: top;
    margin-bottom: 50px;
    color: gold;
    animation: glow 2s infinite linear;
}

@keyframes glow {
    0% {
        text-shadow: 0 0 10px yellow;
    }

    50% {
        text-shadow: 0 0 10px yellow, 0 0 20px yellow;
    }

    100% {
        text-shadow: 0 0 10px yellow;
    }
}


.auth-card {
    width: 100%;
    padding: 20px;
}


/* Media query for smaller screens */
@media (max-width: 767px) {
    .login-card {
        flex-direction: column;
    }

    .left-side {
        width: 100%;
        padding-top: 27px;
    }

    .right-side {
        padding: 0px;
        padding-top: 0px;
        border-radius: 10px 10px 0px 0px;
    }

    .auth-card {
        border-radius: 0px;
        width: 100%;
        max-width: 100%;
        padding-left: 61px;
        padding-right: 62px;
    }

    .auth-card-text {
        /* text-align: center;
        color: #FFF;
        margin-bottom: 50px; */
        display: none;
    }

}