.my-downline-root {
    height: 100%;
    background: white;
}

/* .my-downline-table-container {
    margin: 0px;
    margin-top: 0px;
} */

.my-downline-table-container-commission-limit {
    margin: 5px;
    margin-top: 0px;
}

.my-downline-commission-limit-root {
    margin-top: 0px;
}

.my-downlines-csv-pdf-search-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-bottom: 5px;
    align-items: baseline;
}

.my-downlines-csv-pdf-container {
    justify-content: center;
    text-align: left;
    width: 100%;
}

.my-downlines-search-container {
    justify-content: center;
    text-align: right;
}

@media (orientation: portrait) {
    .my-downline-root {
        margin-top: 0px;
        padding: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    /* .my-downline-table-container {
        padding-top: 5px;
    } */

    .my-downline-table-container-commission-limit {
        padding-top: 0px;
    }

    .my-downline-commission-limit-root {
        margin-top: 0px;
    }

    .my-downlines-csv-pdf-search-container {
        padding-left: 10px;
    }
}