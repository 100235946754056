.navbar {
    height: 80px;
    background-color: #4F2100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 200;
    top: 0;
    left: 0;
}

.menu-bars {
    margin-left: 1rem;
    font-size: 2rem;
    background: none;
}

.nav-menu {
    background-color: #301600;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
}

.nav-menu.active {
    left: 0;
    transition: 350ms;
}

.sidebar{
    background-color: #4F2100;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    transition: 850ms;
    z-index: 2; 
}

.nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
}

.nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 4px;
}

.nav-text a:hover {
    background-color: #301600;
}

.nav-menu-items {
    width: 100%;
    box-sizing: border-box;
}

.navbar-toggle {
    background-color: #4F2100;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
}

span {
    margin-left: 16px;
}

.signup{
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    margin-right: 2rem;
}