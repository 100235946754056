.card {
  border: 1px solid #ddd;
  margin-bottom: 10px;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
  width: 100%; /* Full width */
}

.card-header {
  background-color: #f1f1f1;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.card-title {
  font-size: 16px;
  font-weight: bold;
}

.card-content {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.second-table-container {
  overflow-x: auto; /* Ensure horizontal scrolling if needed */
}

@media (orientation: portrait) {
  .card-content {
    padding: 0px;
  }
}
