.match-odds-betting-top-container {
    width: 100%;
    margin-bottom: 0px;
    overflow: auto;
}

.match-odds-betting-market-container {
    border: 1px solid grey;
    border-radius: 0px 10px 5px 0px;
}

.match-odds-betting-market-container-fancy {
    border: 1px solid grey;
    border-radius: 0px 10px 5px 0px;
    width: 723px;
    padding-bottom: 10px;
}

.bet-market-header-matched-amount-container {
    /* margin-top: 1px; */
    margin-top: 1px;
    border: 1px solid grey;
    border-bottom: none;
    display: flex;
    flex-direction: row;
}

.bet-market-header-container {
    width: 30%;
}

.cric-bet-market-header {
    width: fit-content;
    background: #041E42;
    color: #f8f8f8;
    border-radius: 0 8px 0 0;
    padding: 5px;
    padding-right: 10px;
    font-size: 14px;
}

.matched-amount {
    width: 70%;
    text-align: right;
    padding: 5px;
}

.match-odds-market-sub-header-container {
    border: 1px solid grey;
    border-top: none;
    border-right: none;
    border-left: none;
    display: flex;
    flex-direction: row;
    max-height: 31px;
}

.match-odds-market-sub-header-container-fancy {
    border: 1px solid grey;
    border-top: none;
    border-right: none;
    border-left: none;
    display: flex;
    flex-direction: row;
    max-height: 31px;
}

.match-odds-market-sub-header-empty {
    width: 47%;
}

.back-header-container {
    /* border: 1px solid purple; */
    /* width: 14%; */
    width: 21.3%;
    text-align: center;
}

.back-header-container-fancy {
    /* border: 1px solid purple; */
    /* width: 14%; */
    width: 12.8%;
    text-align: center;
}

.back-header {
    /* background: #42a5f6; */
    /* background: rgb(40 108 79 / 60%); */
    background: #f8f8f8;
    color: black;
    /* border-radius: 10px 0 0 0; */
    padding: 5px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 4px;
    padding-bottom: 4px;
    border: 1px solid rgb(137, 151, 155);
    box-shadow: rgb(6 22 33 / 30%) 0px 1px 2px;
    font-size: 14px;
}

.back-header-fancy {
    /* background: #42a5f6; */
    /* background: rgb(40 108 79 / 60%); */
    background: #f8f8f8;
    color: black;
    /* border-radius: 10px 0 0 0; */
    padding: 5px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 4px;
    padding-bottom: 4px;
    border: 1px solid rgb(137, 151, 155);
    box-shadow: rgb(6 22 33 / 30%) 0px 1px 2px;
    font-size: 14px;
}

.back-header-bookmaker {
    /* background: #42a5f6; */
    /* background: rgb(40 108 79 / 60%); */
    /* color: black; */
    border-radius: 10px 0 0 0;
    padding: 5px;
    padding-left: 0px;
    padding-right: 0px;
}

.lay-header-container {
    /* width: 14%; */
    width: 21.2%;
    text-align: center;
}

.lay-header-container-fancy {
    /* width: 14%; */
    width: 12.5%;
    text-align: center;
}

.lay-header {
    /* background: #ff91af; */
    /* background: #F9A9BA; */
    background: #494F55;
    color: #f8f8f8;
    /* border-radius: 0 10px 0 0; */
    padding: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    padding-right: 0px;
    border: 1px solid rgb(137, 151, 155);
    box-shadow: rgb(6 22 33 / 30%) 0px 1px 2px;
    font-size: 14px;
}

.lay-header-fancy {
    /* background: #ff91af; */
    /* background: #F9A9BA; */
    background: #494F55;
    color: #f8f8f8;
    border-radius: 0 0 0 0;
    padding: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    padding-right: 0px;
    border: 1px solid rgb(137, 151, 155);
    box-shadow: rgb(6 22 33 / 30%) 0px 1px 2px;
    font-size: 14px;
}

.position-header-container {
    /* width: 14%; */
    width: 12.5%;
    text-align: center;
}

.position-header {
    /* background: #ff91af; */
    /* background: #F9A9BA; */
    background: floralwhite;
    color: #494F55;
    border-radius: 0 0 0 0;
    padding: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    padding-right: 0px;
    border: 1px solid rgb(137, 151, 155);
    box-shadow: rgb(6 22 33 / 30%) 0px 1px 2px;
    font-size: 14px;
}

.fancy-actions-container {
    /* width: 14%; */
    width: 4.2%;
    text-align: center;
}

.fancy-actions {
    /* background: #ff91af; */
    /* background: #F9A9BA; */
    background: #e8e8e8;
    color: #494F55;
    /* border-radius: 0 10px 0 0; */
    padding: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    padding-right: 0px;
    border: 1px solid rgb(137, 151, 155);
    box-shadow: rgb(6 22 33 / 30%) 0px 1px 2px;
    font-size: 14px;
    height: 100%;
}

.lay-header-bookmaker {
    /* background: #ff91af; */
    /* background: #F9A9BA; */
    border-radius: 0 10px 0 0;
    padding: 5px;
    padding-left: 0px;
    padding-right: 0px;
}

.min-max-amount-container {
    /* width: 28%; */
    width: 58.5%;
}

.min-max-amount-container-fancy {
    /* width: 28%; */
    width: 46.2%;
    display: flex;
    flex-direction: row;
}

.min-max-amount {
    background-color: #e8e8e8;
    text-align: left;
    font-size: 1em;
    font-weight: 600;
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
    align-content: center;
    color: #20b2aa;
    height: 100%;
}

.min-max-amount-fancy {
    /* display: flex;
    flex-direction: column; */
    background-color: #e8e8e8;
    text-align: left;
    font-size: 1em;
    font-weight: 700;
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
    align-content: center;
    color: #20b2aa;
}

.match-bet-panel-top-container {
    height: 100%;
    border: 1px solid black;
    border-top: none;
}

.match-team-lay-back-container {
    display: flex;
    flex-direction: row;
}

.match-team-name-exposure-container-mlb {
    /* border: 1px solid red; */
    padding-top: 12px;
    padding-left: 10px;
    width: 18.7%;
    height: 60px;
    font-size: 18px;
    justify-content: center;
}

.far-right-arrow {
    color: #D0011C;
    font-size: 13px;
    margin-right: 5px;
}

.back-lay-button-container {
    /* border: 0.5px solid black; */
    width: 14%;
}

.back-button-1 {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding: 10px 16px;
    background-color: #42a5f6;
    cursor: pointer;
    font-size: 18px;
}

.active,
.back-button-1:hover {
    background-color: #1976d2;
    color: white;
}

.back-button-1:focus {
    background-color: #1976d2;
    color: white;
    box-shadow: inset 3px 3px 5px 0 rgba(0, 0, 0, 0.2), inset -3px -3px 5px 0 rgba(0, 0, 0, 0.2);
}

.back-button-2 {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding: 10px 16px;
    background-color: #90caf9;
    cursor: pointer;
    font-size: 18px;
}

.active,
.back-button-2:hover {
    background-color: #1976d2;
    color: white;
}

.back-button-2:focus {
    background-color: #1976d2;
    color: white;
    box-shadow: inset 3px 3px 5px 0 rgba(0, 0, 0, 0.2), inset -3px -3px 5px 0 rgba(0, 0, 0, 0.2);
}

.back-button-3 {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding: 10px 16px;
    background-color: #bbdefb;
    cursor: pointer;
    font-size: 18px;
}

.active,
.back-button-3:hover {
    background-color: #1976d2;
    color: white;
}

.back-button-3:focus {
    background-color: #1976d2;
    color: white;
    box-shadow: inset 3px 3px 5px 0 rgba(0, 0, 0, 0.2), inset -3px -3px 5px 0 rgba(0, 0, 0, 0.2);
}

.lay-button-1 {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding: 10px 16px;
    background-color: #ff91af;
    cursor: pointer;
    font-size: 18px;
}

.active,
.lay-button-1:hover {
    background-color: #ff69b4;
    color: white;
}

.lay-button-1:focus {
    background-color: #ff69b4;
    color: white;
    box-shadow: inset 3px 3px 5px 0 rgba(0, 0, 0, 0.2), inset -3px -3px 5px 0 rgba(0, 0, 0, 0.2);
}

.lay-button-2 {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding: 10px 16px;
    background-color: #ffc1cc;
    cursor: pointer;
    font-size: 18px;
}

.active,
.lay-button-2:hover {
    background-color: #ff69b4;
    color: white;
}

.lay-button-2:focus {
    background-color: #ff69b4;
    color: white;
    box-shadow: inset 3px 3px 5px 0 rgba(0, 0, 0, 0.2), inset -3px -3px 5px 0 rgba(0, 0, 0, 0.2);
}

.lay-button-3 {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding: 10px 16px;
    background-color: #ffe4e1;
    cursor: pointer;
    font-size: 18px;
}

.active,
.lay-button-3:hover {
    background-color: #ff69b4;
    color: white;
}

.lay-button-3:focus {
    background-color: #ff69b4;
    color: white;
    box-shadow: inset 3px 3px 5px 0 rgba(0, 0, 0, 0.2), inset -3px -3px 5px 0 rgba(0, 0, 0, 0.2);
}

.market-status-live {
    width: 100%;
    height: 100%;
    position: relative;
}

.market-status-not-live {
    width: 100%;
    height: 100%;
    padding-top: 16px;
    position: absolute;
    color: red;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    background-color: white;
    opacity: 0.5;
    border: 1px solid red;
    overflow: hidden;
}

.hr-solid {
    margin-top: 10px;
    margin-bottom: 10px;
    border-top: 3px solid #bbb;
}

@media (orientation: portrait) {

    .cric-bet-market-header {
        font-size: 15px;
        padding-top: 6px;
        padding-bottom: 6px;
        font-size: 12px;
    }

    .matched-amount {
        font-size: 12px;
    }

    .back-header {
        font-size: 12px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 6px;
        padding-bottom: 5px;
    }

    .lay-header {
        font-size: 12px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 6px;
        padding-bottom: 5px;
    }

    .min-max-amount {
        font-size: 0.8em;
        font-weight: 700;
        padding-top: 2px;
    }

    .min-max-amount-fancy {
        font-size: 0.8em;
        font-weight: 700;
        padding-top: 2px;
    }

    .match-team-name-exposure-container-mlb {
        padding-right: 1px;
        padding-top: 5px;
        padding-left: 5px;
        padding-bottom: 5px;
        font-size: 12px;
    }

    .far-right-arrow {
        margin-top: 10px;
        font-size: 10px;
    }

    .exposure-amount {
        font-size: 13px;
    }

    .back-button-1 {
        padding: 0px 0px;
        font-size: 10px;
    }

    .back-button-1:focus {
        box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.2), inset -1px -1px 2px 0 rgba(0, 0, 0, 0.2);
    }

    .back-button-2 {
        padding: 0px 0px;
        font-size: 10px;
    }

    .back-button-2:focus {
        box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.2), inset -1px -1px 2px 0 rgba(0, 0, 0, 0.2);
    }

    .back-button-3 {
        padding: 0px 0px;
        font-size: 10px;
    }

    .back-button-3:focus {
        box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.2), inset -1px -1px 2px 0 rgba(0, 0, 0, 0.2);
    }

    .lay-button-1 {
        padding: 0px 0px;
        font-size: 10px;
    }

    .lay-button-1:focus {
        box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.2), inset -1px -1px 2px 0 rgba(0, 0, 0, 0.2);
    }

    .lay-button-2 {
        padding: 0px 0px;
        font-size: 10px;
    }

    .lay-button-2:focus {
        box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.2), inset -1px -1px 2px 0 rgba(0, 0, 0, 0.2);
    }

    .lay-button-3 {
        padding: 0px 0px;
        font-size: 10px;
    }

    .lay-button-3:focus {
        box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.2), inset -1px -1px 2px 0 rgba(0, 0, 0, 0.2);
    }

    /* .match-status-live {
        width: 100%;
        height: 70px;
        position: relative;
    }

    .match-status-not-live {
        width: 100%;
        height: 54px;
        padding-top: 16px;
    } */

    .min-max-amount-container {
        /* width: 28%; */
        width: 56.9%;
    }

    .min-max-amount-container-fancy {
        /* width: 28%; */
        width: 42.7%;
    }

    .back-header-container {
        /* border: 1px solid purple; */
        /* width: 14%; */
        width: 22%;
    }

    .back-header-container-fancy {
        /* border: 1px solid purple; */
        /* width: 14%; */
        width: 13%;
    }

    .lay-header-container {
        /* width: 14%; */
        width: 22%;
    }

    .lay-header-container-fancy {
        /* width: 14%; */
        width: 13%;
    }

    .match-odds-betting-market-container-fancy {
        width: 540px;
    }

    .fancy-actions-container {
        /* width: 14%; */
        width: 5.5%;
    }

    .position-header-container {
        /* width: 14%; */
        width: 12.88%;
    }
}