.first-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .first-table th,
  .first-table td {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 0.75rem;
    text-align: center;
  }
  
  .first-table th {
    background-color: #333;
    color: #fff;
  }
  
  .first-table td {
    background-color: #f5f5f5;
  }
  