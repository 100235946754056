.supported-sports-root {
    background-image: url('/src/assets/football_blue.jpg');
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
}

.cric-supported-sports-root {
    height: 10vh;
    /* background-image: url('/src/assets/football_blue.jpg');
    background-size: cover;
    background-repeat: no-repeat; */
}

:root {
    --card-height: 400px;
    --card-width: calc(var(--card-height) / 1.5);
}

.game-card2-root-container {
    /* border: 0.8px solid #ffd700; */
    border-radius: 25px;
    width: 100%;
    height: fit-content;
    padding-left: 40px;
    padding-right: 40px;
}

.game-card2-container {
    width: 100%;
    margin-top: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    float: bottom;
    /* background: radial-gradient(ellipse at bottom, #35654d 0%, #090a0f 100%); */
    padding: 5px;
    /* background: #4f3b5b;
    background: radial-gradient(ellipse at center,  #4f3b5b 0%,#231733 100%); */
    /* background: -moz-radial-gradient(center, ellipse cover,  #4f3b5b 0%, #231733 100%); */
    /* background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,#4f3b5b), color-stop(100%,#231733)); */
    /* background: -webkit-radial-gradient(center, ellipse cover,  #4f3b5b 0%,#231733 100%); */
    /* background: -o-radial-gradient(center, ellipse cover,  #4f3b5b 0%,#231733 100%); */
    /* background: -ms-radial-gradient(center, ellipse cover,  #4f3b5b 0%,#231733 100%); */
    /* background: radial-gradient(ellipse at center,  #4f3b5b 0%,#231733 0%); */
    /* background: #834333;
    background: #5F8575; */
    border: none;
    outline: none;
    /* text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
    border: 1px solid rgba(0,0,0,0.3);
    box-shadow: inset 0 -5px 45px rgba(100,100,100,0.2), 0 1px  1px rgba(255,255,255,0.2); */
    transition: all .3s ease-in-out;
    position: relative;
    /* border: 1px solid white; */
}

.landing-page2-coming-soon-container {
    display: flex;
    flex-direction: column;
    width: 300px;
}

.landing-page2-games-coming-soon-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.game-card2 {
    /* border: 1px solid white; */
    width: var(--card-width);
    height: var(--card-height);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    perspective: 2500px;
    /* margin: 0 50px; */
    /* background-color: transparent; */
}

.game-card2-cover-image {
    width: 268px;
    height: 150px;
    margin-top: 100px;
    object-fit: cover;
    border-radius: 25px;
    border: 1px solid #ffd700;
}

.game-card2-wrapper {
    transition: all 0.5s;
    position: absolute;
    width: 100%;
    z-index: -1;
    border-radius: 25px;
}

.game-card2:hover .game-card2-wrapper {
    transform: perspective(900px) translateY(-5%) rotateX(25deg) translateZ(0);
    /* box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75); */
    box-shadow: 2px 35px 32px -8px #ffd700;
    -webkit-box-shadow: 2px 35px 32px -8px #ffd700;
    -moz-box-shadow: 2px 35px 32px -8px #ffd700;
    border-radius: 25px;
    /* margin-top: 100px; */
}

.game-card2:focus .game-card2-wrapper {
    transform: perspective(900px) translateY(-5%) rotateX(25deg) translateZ(0);
    /* box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75); */
    box-shadow: 2px 35px 32px -8px #ffd700;
    -webkit-box-shadow: 2px 35px 32px -8px #ffd700;
    -moz-box-shadow: 2px 35px 32px -8px #ffd700;
    border-radius: 25px;
    /* margin-top: 100px; */
}

.game-card2-wrapper::before,
.game-card2-wrapper::after {
    content: "";
    opacity: 0;
    width: 100%;
    height: 80px;
    transition: all 0.5s;
    position: absolute;
    left: 0;
    border-radius: 25px;
    margin-top: 100px;
}

.game-card2-wrapper::before {
    top: 0;
    height: 100%;
    background-image: linear-gradient(to top,
            transparent 46%,
            rgba(12, 13, 19, 0.5) 68%,
            rgba(12, 13, 19) 97%);
}

.game-card2-wrapper::after {
    bottom: 0;
    opacity: 1;
    background-image: linear-gradient(to bottom,
            transparent 46%,
            rgba(12, 13, 19, 0.5) 68%,
            rgba(12, 13, 19) 97%);
}

.game-card2:hover .game-card2-wrapper::before,
.game-card2-wrapper::after {
    opacity: 1;
}

.game-card2:hover .game-card2-wrapper::after {
    height: 120px;
}

.game-title-text {
    margin-top: 300px;
    font-size: 30px;
    /* color: #48aaad; */
    color: white;
    text-decoration: underline;
}

.game-card2-title {
    width: 100%;
    transition: transform 0.5s;
    margin-top: 160px;
}

.game-card2:hover .game-card2-title {
    transform: translate3d(0%, -50px, 100px);
}

.game-card2-character {
    width: 100%;
    opacity: 0;
    transition: all 0.5s;
    position: absolute;
    z-index: -1;
}

.game-card2:hover .game-card2-character {
    opacity: 1;
    transform: translate3d(0%, -30%, 100px);
}

@media (orientation: portrait) {
    /* :root {
        --card-height: 200px;
        --card-width: calc(var(--card-height) / 1.5);
    } */

    /* .supported-sports-root {
        position: absolute;
    } */

    .game-card2-root-container {
        padding-left: 0px;
        padding-right: 0px;
    }

    .game-card2-container {
        flex-direction: column;
    }

    .game-card2-cover-image {
        /* width: 268px; */
        height: 170px;
        margin-top: 0px;
    }

    .game-title-text {
        margin-top: 220px;
    }

    /* .landing-page2-coming-soon-container {
        display: flex;
        flex-direction: column;
        width: 150px;
    }

    .game-card2-title {
        width: 100%;
        transition: transform 0.5s;
        margin-top: 140px;
    }

    .game-card2-title-lucky-number {
        width: 100%;
        transition: transform 0.5s;
        margin-top: 140px;
    } */
}