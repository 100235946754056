.portal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
}

.portal {
    padding: 30px;
    margin: 50px auto;
    width: 30%;
    background: #fff;
    border-radius: 10px;
}

.right {
    position: relative;
    right: 0px;
    margin-left: 385px;
}

.right-settle {
  position: relative;
  right: 0px;
  margin-left: 385px;
}

.container-test {
    width: 150px;
    height: 150px;
    position: relative;
    margin: 30px;
  }
  .box-test {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7;
    background: #0057e3;
  }
  .overlay-test {
    z-index: 9;
    margin: 30px;
    background: #009938;
  }

  .container-settle {
    width: 120px;
    height: 150px;
    position: relative;
    margin: 30px;
  }
  .box-settle {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7;
    background: #0057e3;
  }
  .overlay-settle {
    z-index: 9;
    margin: 30px;
    background: #009938;
  }

  /* Popup style */
.popup-box {
    position: fixed;
    background: #fff;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  
  .box {
    position: relative;
    width: 35%;
    margin: 0 auto;
    height: 100%;
    max-height: auto;
    margin-top: calc(100vh - 85vh - 20px);
    background: #00000090;
    border-radius: 4px;
    border: 1px solid #999;
    overflow: auto;
  }
  
  .close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(33% - 18px);
    top: calc(100vh - 85vh - 25px);
    background: var(--second-color);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
  }