.notifications-popup-container {
    width: 100%;
    /* background-image: url('/src/assets/login_background.jpg'); */
    height: 100%;
    border: 1px solid black;
    border-radius: none;
    padding: 5%;
    border-radius: 10px;
    /* background-size: cover;
    background-repeat: no-repeat; */
  }
  
  .notifications-popup-header {
    background: #1daab5;
    width: 100%;
    color: white;
    font-size: 14;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 500;
    height: 50px;
    padding-top: 12px;
  }
  
  .notifications-popup-body {
    width: 100%;
    color: #676a6c;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: left;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 30px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    text-align: center;
    min-height: 60%;
  }
  
  .notifications-popup-title {
    color: rgb(87, 87, 87);
  }
  
  .notifications-popup-value {
    color: #71BC78;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  
  .notifications-popup-separator {
    width: 100%;
    background: #e2e2e2;
    height: 1px;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  
  .notifications-popup-close-button {
    width: 100;
    align-items: center;
    height: 50px;
  }