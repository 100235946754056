.exposure-table-root {
    max-height: 500px;
    overflow-y: auto;
}

.exposure-table {
    border-collapse: collapse;
    border: 0.5px solid white;
}

.exposure-table-th-big {
    color: white;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 13px;
    font-weight: 400;
    text-align: left;
    width: 50%;
    padding-left: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    border: 0.5px solid #d5d5d5;
    border-bottom: none;
    background: #2f4050;
}

.exposure-table-td-big {
    font-family: "Roboto Condensed", sans-serif;
    color: #676a6c;
    font-size: 13px;
    font-weight: 400;
    width: 50%;
    padding: 2px;
    padding-left: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    border: 0.5px solid #d5d5d5;
}

.exposure-table-th-medium {
    color: white;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 13px;
    font-weight: 400;
    text-align: left;
    width: 30%;
    padding-left: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    border: 0.5px solid #d5d5d5;
    border-bottom: none;
    background: #2f4050;
}

.exposure-table-td-medium {
    font-family: "Roboto Condensed", sans-serif;
    color: #676a6c;
    font-size: 13px;
    font-weight: 400;
    width: 30%;
    padding: 2px;
    padding-left: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    border: 0.5px solid #d5d5d5;
}

.exposure-table-th-small {
    font-family: "Roboto Condensed", sans-serif;
    color: white;
    font-size: 13px;
    font-weight: 400;
    text-align: left;
    padding-left: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    width: 20%;
    background: #2f4050;
    border: 0.5px solid #d5d5d5;
    border-bottom: none;
}

.exposure-table-td-small {
    font-family: "Roboto Condensed", sans-serif;
    color: #676a6c;
    font-size: 13px;
    font-weight: 400;
    width: 20%;
    text-align: left;
    padding-left: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    border: 0.5px solid #d5d5d5;
}

.disabled td {
    color: #4F5F64;
}

.exposure-table tbody tr {
    transition: background 0.25s ease;
}

/* .exposure-table tbody tr:hover {
    background: #014055;
} */


/* demo styles */

/* @import url(https://fonts.googleapis.com/css?family=Roboto:400,500,300,700);

body {
    background: -webkit-linear-gradient(left, #25c481, #25b7c4);
    background: linear-gradient(to right, #25c481, #25b7c4);
    font-family: 'Roboto', sans-serif;
} */

/* section {
    margin: 50px;
} */


/* follow me template */
/* .made-with-love {
    margin-top: 40px;
    padding: 10px;
    clear: left;
    text-align: center;
    font-size: 10px;
    font-family: arial;
    color: #fff;
}

.made-with-love i {
    font-style: normal;
    color: #F50057;
    font-size: 14px;
    position: relative;
    top: 2px;
}

.made-with-love a {
    color: #fff;
    text-decoration: none;
}

.made-with-love a:hover {
    text-decoration: underline;
} */


/* for custom scrollbar for webkit browser*/

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}