.breadcrumb-not-active {
    color: #cccccc;
}

.breadcrumb-active {
    color: #000000;
}

.breadcrumbs {
    max-width: 1200px;
    margin: 20px auto;
}

.breadcrumbs > *{
    display: inline-block;
    margin-right: 10px;
}

.breadcrumbs .crumb:after {
    content: '>';
    margin-left: 10px;
}

.breadcrumbs .crumb:last-child:after {
    display: none;
}

.breadcrumbs .crumb a {
    color: #ddd;
}