.bet-slip-table-header {
    font-size: 12px;
    /* font-weight: bold; */
    color: #ABAEB0;
    text-align: left;
    font-weight: bolder;
    padding-left: 10px;
    white-space: nowrap;
    word-wrap: break-word;
    width: 100%;
    padding: 10px;
}

.bet-slip-table-td {
    font-size: 8px;
    /* font-weight: bold; */
    color: #5C6063;
    text-align: left;
}

.bet-slip-table-cell-bet-status-settled {
    padding: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    background: #1ab293;
    color: white;
    font-size: 11px;
    font-weight: 600;
}

.bet-slip-table-cell-bet-status-unsettled {
    padding: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    background: #fcae1e;
    color: white;
    font-size: 11px;
    font-weight: 600;
}