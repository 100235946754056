.agent-dashboard-root {
    font-family: "Roboto Condensed", sans-serif;
    padding-bottom: 20px;
}

.cric-all-matches-button-container {
    display: flex;
    flex-direction: row;
    padding-top: 8px;
    padding-left: 5px;
    padding-right: 5px;
}

.cric-sports-button-container {
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.cric-agent-matches-dashboard-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 5px;
    padding: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 100%;
    /* background: green; */
}

.cric-agent-matches-dashboard-buttons-container {
    display: flex;
    flex-direction: row;
}

.cric-agent-matches-dashboard-buttons-parent {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.cric-agent-matches-dashboard-buttons-container-top {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.cric-agent-matches-dashboard-coins-rs-exposure-container {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.cric-agent-matches-dashboard-coins-rs-exposure {
    margin-right: 20px;
    background: white;
    padding-bottom: 20px;
    width: 40%;
}

.cric-agent-matches-dashboard-coins-rs-exposure-value {
    text-align: left;
    padding-left: 20px;
    padding-top: 20px;
    font-size: 25px;
    font-family: "Roboto Condensed", sans-serif;
    color: #676a6c;
    font-weight: 700;
}

@media (orientation: portrait) {
    .cric-all-matches-button-container {
        border: 1px 0 0 black;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 2px;
        padding-right: 2px;
    }

    .cric-sports-button-container {
        display: flex;
        flex-direction: column;
        padding-top: 0px;
        padding-left: 2px;
        padding-right: 2px;
    }

    .cric-agent-matches-dashboard-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 0px;
    }

    .cric-agent-matches-dashboard-buttons-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
    }

    .cric-agent-matches-dashboard-buttons-container-mini {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
    }

    .cric-agent-matches-dashboard-coins-rs-exposure-container {
        display: flex;
        flex-direction: column;
        margin-top: 0px;
    }

    .cric-agent-matches-dashboard-coins-rs-exposure {
        margin-right: 0px;
        margin-top: 10px;
        width: 100%;
    }
}