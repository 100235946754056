.bet-slip-root {
    background: transparent;
}

@font-face {
    font-family: 'ThinFont';
    src: url('../../../assets/genome.thin.otf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

.bet-slip-holder-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 5px;
}

.header-detail-container {
    width: 100%;
    max-width: 600px;
    /* margin: 20px auto; */
    /* border: 1px solid #ccc; */
    border-radius: 2px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 0px;
    padding-right: 0px;
    background-color: white;
    margin-left: 0px;
    margin-right: 10px;
    border-top: 4px solid #f2f2f2;
}

.header {
    font-size: 14px;
    color: #676a6c;
    text-align: left;
    font-weight: 900;
    padding-left: 10px;
    line-height: 15px;
}

.separator {
    width: 100%;
    background: #e2e2e2;
    height: 0.1px;
    margin-top: 15px;
    margin-bottom: 10px;
}

.detail {
    font-size: 30px;
    color: #676a6c;
    text-align: left;
    padding-left: 15px;
    /* font-family: 'ThinFont', sans-serif; */
    font-family: "open sans", "helvetica neue", Helvetica, Arial, sans-serif;
    font-weight: 200;
    line-height: 33px;
    /* Thin weight */
}

.bet-slip-betsman-dropdown {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 5px;
    display: flex;
    flex-direction: row;
}

.bet-slip-users-dropdown {
    width: 100%;
    max-width: 40%;
    margin-right: 15px;
}

.bet-slip-winner-toss-dropdown {
    width: 100%;
    max-width: 42%;
}

.bet-slip-tab-container {
    width: 98%;
    padding-top: 10px;
    margin-left: 0px;
    /* padding-right: 8px; */
}

/* .bet-slip-tab-header-container {
    text-align: left;
    padding-left: 5px;
} */

.bet-slip-tab-header-container {
    padding-top: 15px;
    font-size: 13px;
    font-weight: bold;
    color: #333;
    text-align: left;
    padding-left: 5px;
}

.bet-slip-holder-container-tab {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0px;
    padding-top: 50px;
    padding-left: 10px;
}

.bet-slip-market-position-container {
    margin-right: 10px;
    margin-left: 5px;
    padding-bottom: 30px;
    margin-left: 10px;
    background: white;
}

.header-market-position-container {
    width: 100%;
    /* margin: 20px auto; */
    /* border: 1px solid #ccc; */
    border-radius: 2px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 0px;
    background-color: white;
    /* margin-left: 10px; */
    border-top: 3px solid #f2f2f2;
    padding-left: 0px;
    margin-top: 20px;
    /* margin-left: 5px; */
}

.header-bet-slip-table-container {
    width: 99%;
    /* margin: 20px auto; */
    /* border: 1px solid #ccc; */
    border-radius: 2px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 0px;
    background-color: white;
    /* margin-left: 10px; */
    border-top: 3px solid #f2f2f2;
    padding-left: 0px;
    margin-top: 20px;
    /* margin-left: 5px;
    background: purple; */
}

.bet-slip-table-parent-close {
    width: 80%;
    min-width: 1200px;
    max-width: 1200px;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 2%;
    padding-top: 0%;
    scrollbar-width: none;
}

.bet-slip-table-parent-open {
    width: 80%;
    min-width: 1000px;
    max-width: 1000px;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 2%;
    padding-top: 0%;
    scrollbar-width: none;
}

.bet-slip-box-container {
    position: relative;
    width: 100%;
    height: 1px;
    /* Adjust height as needed */
    background-color: white;
}

.bet-slip-box-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 10.2%;
    /* Start the border at 10% of the width */
    width: 90%;
    /* Make the border span the remaining 90% */
    height: 0.5px;
    /* Thickness of the border */
    background-color: #e5e4e2;
    /* Border color */
}

.bet-slip-box-container-2 {
    position: relative;
    width: 100%;
    height: 1px;
    /* Adjust height as needed */
    background-color: white;
}

.bet-slip-box-container-2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 10.2%;
    height: 0.5px;
    /* Thickness of the border */
    background-color: #e5e4e2;
    /* Border color */
}

/* Last 70% with border */
.bet-slip-box-container-2::after {
    content: "";
    position: absolute;
    top: 0;
    left: 24.85%;
    /* Start after the first 30% (10% border + 20% no border) */
    width: 75.1%;
    /* Cover the remaining 70% */
    height: 0.5px;
    /* Thickness of the border */
    background-color: #e5e4e2;
    /* Border color */
}

.market-position-runner-position-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-left: 25px;
    background: white;
}

.bet-slip-market-position-runner-container {
    width: 50%;
}

.market-separator-below-header {
    width: 100%;
    background: #e1e1e1;
    height: 0.5px;
    margin-top: 15px;
    margin-bottom: 10px;
}

.market-separator-below-detail {
    width: 100%;
    background: #e1e1e1;
    height: 0.2px;
    margin-top: 15px;
    margin-bottom: 10px;
}

.market-position-detail {
    font-size: 13px;
    color: #676a6c;
    text-align: left;
    padding-left: 10px;
    font-weight: 400;
}

@media (orientation: portrait) {

    .bet-slip-root {
        margin-top: 2px;
    }

    .bet-slip-holder-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 5px;
    }

    .header-detail-container {
        margin: 0px;
        margin-bottom: 10px;
    }

    .bet-slip-betsman-dropdown {
        width: 98%;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 5px;
        flex-direction: column;
    }

    .bet-slip-users-dropdown {
        margin-right: 0px;
        margin-bottom: 10px;
        max-width: 100%;
    }

    .bet-slip-winner-toss-dropdown {
        max-width: 100%;
    }

    .bet-slip-tab-container {
        margin-left: 5px;
    }

    .header-bet-slip-table-container {
        margin-left: 5px;
        width: 96%;
        /* margin: 20px auto; */
        /* border: 1px solid #ccc; */
        border-radius: 2px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 0px;
        background-color: white;
        /* margin-left: 10px; */
        border-top: 3px solid #f2f2f2;
        padding-left: 0px;
        margin-top: 20px;
        /* margin-left: 5px;
        background: purple; */
    }

    .bet-slip-holder-container-tab {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 0px;
    }

    .bet-slip-box-container::before {
        left: 29%;
        /* Start the border at 10% of the width */
        width: 71%;
        /* Make the border span the remaining 90% */
    }

    .bet-slip-box-container-2::before {
        width: 29%;
    }

    .bet-slip-box-container-2::after {
        left: 69.8%;
        /* Start after the first 30% (10% border + 20% no border) */
        width: 30%;
    }

    /* .market-position-runner-position-container {
        display: flex;
        flex-direction: column;
    }

    .bet-slip-market-position-runner-container {
        width: 100%;
    } */

    .bet-slip-table-parent-close,
    .bet-slip-table-parent-open {
        padding-left: 7%;
        overflow-y: hidden;
        overflow-x: auto;
    }
}