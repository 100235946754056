/* src/DynamicTable.css */

.dynamic-table-container {
    width: 100%;
    max-height: 600px;
    overflow: auto;
    /* border: 1px solid #ddd; */
    border-radius: 0px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 16px 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-top: 0px;
    padding-bottom: 40px;
    font-family: Tahoma, sans-serif;
  }
  
  .dynamic-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    min-width: 600px;
  }
  
  .dynamic-table th,
  .dynamic-table td {
    border: 1px solid #ccc;
    padding: 12px;
    text-align: left;
    line-height: 1.2;
  }
  
  .dynamic-table th {
    background-color: #f5f5f5;
    color: #36454F;
    font-weight: bold;
    height: 30px;
  }
  
  .dynamic-table tr {
    height: 28px;
  }
  
  .dynamic-table tr:nth-of-type(even) {
    background-color: #f8f8f8;
  }
  
  .dynamic-table tr:hover {
    background-color: #e8e8e8;
    color: #111;
  }
  
  .dynamic-table td {
    color: #555;
  }
  
  /* Pagination Styles */
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    padding: 5px;
    background: white;
    /* border-top: 1px solid #ddd; */
  }
  
  .pagination-button {
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    color: #007bff;
    padding: 5px 10px;
    margin: 0 2px;
    cursor: pointer;
    font-size: 14px;
    user-select: none;
  }
  
  .pagination-button.active {
    background-color: #007bff;
    color: #fff;
  }
  
  .pagination-button:hover {
    background-color: #e0e0e0;
  }
  
  .pagination-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  .pagination-ellipsis {
    border: none;
    background: none;
    color: #007bff;
    font-size: 14px;
    padding: 5px 10px;
  }

  .td-match-link {
    font-size: 14px;
  }

  .label-profit {
    color: #23c6c7;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    font-family: 'Consolas', monospace;
  }

  .label-loss {
    color: #ed5564;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    font-family: 'Consolas', monospace;
  }
  
  /* Media query for smaller screens */
  @media (orientation: portrait) {
    .dynamic-table-container {
      max-height: 500px;
      overflow: auto;
    }
  
    .dynamic-table {
      min-width: 100wh;
      font-size: 14px; /* Increase font size for mobile */
    }
  
    .dynamic-table th,
    .dynamic-table td {
      padding: 6px; /* Increase padding for mobile */
    }
  
    .pagination {
      position: static;
      overflow-x: auto;
    }
  
    .pagination-button {
      font-size: 14px; /* Increase pagination button font size for mobile */
      padding: 5px 8px; /* Increase padding for pagination buttons */
    }
  }
  