.second-table {
    width: 100%;
    border-collapse: collapse;
    overflow-x: auto;
  }
  
  .second-table th,
  .second-table td {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 0.75rem;
    text-align: center;
  }
  
  .second-table th {
    background-color: #3b5998; /* Facebook blue */
    color: #fff;
  }
  
  .second-table td {
    background-color: #f9f9f9;
  }
  
  .second-table td.col1 {
    background-color: #e0e0e0; /* Light gray */
  }
  
  .second-table td.col2 {
    background-color: #cce5ff; /* Light blue */
  }
  
  .second-table td.col3 {
    background-color: #ffe5d9; /* Light orange */
  }
  