/* src/DynamicTable.css */

.collection-report-table-container {
    /* width: fit-content; */
    /* min-width: 97%; */
    /* max-height: 400px; */
    /* overflow: auto; */
    /* border: 1px solid #ddd; */
    border-radius: 0px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    /* margin: 16px 0; */
    background-color: white;
    display: flex;
    flex-direction: column;
    padding-bottom: 0px;
    width: 100%;
    overflow: hidden;
}

.collection-report-table {
    width: 88%;
    border-collapse: collapse;
    font-size: 12px;
    min-width: 300px;
    margin-left: 24px;
}

.collection-report-table th,
.collection-report-table td {
    padding: 0px;
    text-align: left;
    /* font-family: 'Consolas', monospace; */
    font-family: "Roboto Condensed", sans-serif;
    color: #676a6c;
    font-size: 13px;
    font-weight: 400;
    border: none;
    padding-top: 12px;
    padding-bottom: 12px;
}

.collection-report-table th {
    /* background-color: #f5f5f5; */
    border: none;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #676a6c;
    height: 40px;
    padding: 0px;
}

.collection-report-table>tbody>tr {
    border-top: 1px solid #e2e2e2;
}

/* .collection-report-table>tbody>tr:last-child {
    border: none;
} */

/* .collection-report-table tr:nth-child(even) {
    background-color: #f8f8f8;
  } */

.collection-report-table>tbody>tr:hover {
    background-color: #f8f8f8;
    color: #111;
}

.collection-report-table td {
    color: #555;
}

/* Pagination Styles */
.collection-report-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    padding: 5px;
    background-color: #fafafa;
    border-top: 1px solid #ddd;
}

.collection-report-pagination-button {
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    color: #007bff;
    padding: 5px 10px;
    margin: 0 2px;
    cursor: pointer;
    font-size: 12px;
    user-select: none;
}

.collection-report-pagination-button.active {
    background-color: #007bff;
    color: #fff;
}

.collection-report-pagination-button:hover {
    background-color: #e0e0e0;
}

.collection-report-pagination-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.collection-report-pagination-ellipsis {
    border: none;
    background: none;
    color: #007bff;
    font-size: 12px;
    padding: 5px 10px;
}

.collection-report-container {
    display: flex;
    flex-direction: row;
    /* margin-left: 5px;
    margin-right: 5px; */
    width: 100%;
    justify-content: space-evenly;
}

.collection-container {
    width: 32.5%;
}

.collection-container-match {
    width: 49%;
}

.th-statement {
    text-align: left;
}

.td-statement-amount {
    /* width: 100%; */
    display: flex;
    justify-content: flex-end;
}

.collection-report-name-link {
    height: 100%;
    padding-left: 0px;
    text-decoration: none;
    color: #337ab7;
    font-size: 13px;
    font-weight: 700;
    text-align: left;
    background: transparent;
}

.collection-report-name-link:hover {
    color: #22527c;
}

/* Media query for smaller screens */
@media (orientation: portrait) {
    .collection-report-table-container {
        /* max-height: 350px; */
        overflow: hidden;
        padding-left: 0px;
        padding-right: 0px;
    }

    .collection-report-table {
        /* min-width: 100wh; */
        font-size: 14px;
        /* Increase font size for mobile */
    }

    .collection-report-table th,
    .collection-report-table td {
        padding: 12px;
        /* Increase padding for mobile */
    }

    .collection-report-pagination {
        position: static;
        overflow-x: auto;
    }

    .collection-report-pagination-button {
        font-size: 14px;
        /* Increase pagination button font size for mobile */
        padding: 5px 8px;
        /* Increase padding for pagination buttons */
    }

    .collection-report-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        /* margin-top: 40px;
        margin-left: 5px;
        margin-right: 5px;
        width: 100%;
        justify-content: space-evenly; */
    }

    .collection-container {
        width: 98%;
        margin-bottom: 20px;
        margin-left: 4px;
    }

    .collection-container-match {
        width: 98%;
        margin-bottom: 20px;
        margin-left: 4px;
    }
}