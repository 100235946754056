.cric-home-root {
    /* background-image: url('/src/assets/login_background.jpg'); */
    height: 100%;
    /* background-size: cover;
    background-repeat: no-repeat; */
}

.home-top-container {
    padding-top: 0%;
    justify-content: center;
}

.home-container {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 5px;
    /* border: 1px solid red; */
}

.home-profile-container {
    margin-left: auto;
    margin-right: auto;
    /* max-width: 70% !important;
    background-color: #f7f7f7; */
    /* padding: 1px; */
    /* box-shadow: 0px 0px 10px rgba(154, 154, 154, 154); */
    /* margin: 0 auto 25px;
    margin-top: 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3); */
}

.home-profile-row {
    display: flex;
    justify-content: space-between;
}

.home-profile-row-for-2 {
    width: 66.7%;
    display: flex;
    justify-content: space-between;
}

.home-profile-content-container {
    width: 33%;
    height: fit-content;
    display: block;
    direction: column;
    justify-content: center;
    margin-bottom: 20px;
    background-color: white;
    border: 1px solid #e8e8e8;
    margin-left: 4px;
    margin-right: 4px;
    /* padding: 0px;
    margin: 0 auto 0px;
    margin-top: 0px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3); */
}

.home-profile-content-container-for-2 {
    width: 50%;
    height: fit-content;
    display: block;
    direction: column;
    justify-content: center;
    margin-bottom: 20px;
    background-color: white;
    border: 1px solid #e8e8e8;
    margin-left: 4px;
    margin-right: 4px;
    /* padding: 0px;
    margin: 0 auto 0px;
    margin-top: 0px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3); */
}

.home-profile-title-container {
    text-align: left;
    background-color: #3963af;
    color: white;
    font-size: 14px;
    font-weight: bold;
    padding: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.home-profile-detail-container {
    color: rgb(103, 106, 108);
    text-align: left;
    padding: 8px;
}

.home-profile-detail-label {
    font-size: 30px;
}

.home-profile-detail-sub-label {
    font-size: 14px;
}

@media (orientation: portrait) {

    .home-root {
        background-image: url('');
        height: 100%;
    }

    .cric-home-root {
        /* background-image: url('/src/assets/login_background.jpg'); */
        height: 100%;
        /* background-size: cover;
        background-repeat: no-repeat; */
    }

    .home-top-container {
        padding-top: 0%;
        width: 100%;
    }

    .home-container {
        width: 100%;
    }

    .home-profile-container {
        margin-left: auto;
        margin-right: auto;
        max-width: 600px !important;
        background-color: #f7f7f7;
        /* padding: 2px;
        margin: 0 auto 25px;
        margin-top: 10px;
        -moz-border-radius: 2px;
        -webkit-border-radius: 2px;
        border-radius: 2px;
        -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3); */
    }

    .home-profile-row {
        display: block;
    }

    .home-profile-row-for-2 {
        width: 100%;
        display: block;
    }

    .home-profile-content-container {
        width: 97%;
    }

    .home-profile-content-container-for-2 {
        width: 97%;
    }

    .home-profile-title-container {
        text-align: left;
        background-color: #3963af;
        color: white;
        font-size: 14px;
        font-weight: normal;
        padding: 8px;
    }

    .home-profile-detail-container {
        text-align: left;
        padding: 10px;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-left: 10px;
    }

    .home-profile-detail-label {
        font-size: 30px;
    }

    .home-profile-detail-sub-label {
        font-size: 14px;
        margin-top: 0px;
    }
}