.agent-table-root {
    background: white;
    overflow-x: auto;
    padding: 20px;
    padding-bottom: 50px;
    scrollbar-width: none;
}

.agent-table-container {
    width: 100%;
    /* max-width: 1200px; */
    /* min-width: 800px; */
    border: 0.5px solid #dcdcdc;
    border-radius: 1px;
    scrollbar-width: none;
}

.agent-table-custom {
    border-collapse: collapse;
    scrollbar-width: none;
    /* background: green; */
}

.agent-table-header {
    display: flex;
    flex-direction: row;
}

.agent-table-header-cell-grey {
    background-color: #f5f5f6;
    color: rgb(103, 106, 108);
    font-weight: 700;
    font-size: 13px;
    padding: 10px;
    padding-left: 8px;
    vertical-align: top;
    text-align: left;
    border-right: 0.5px solid #dcdcdc;
    border-bottom: 2px solid #dcdcdc;
    cursor: pointer;
    /* max-width: 8%; */
    min-width: 8%;
    min-height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.agent-table-header-cell {
    background-color: #ffffff;
    color: rgb(103, 106, 108);
    font-weight: 700;
    font-size: 13px;
    padding: 10px;
    padding-left: 8px;
    vertical-align: top;
    text-align: left;
    border-right: 0.5px solid #dcdcdc;
    border-bottom: 2px solid #dcdcdc;
    cursor: pointer;
    /* max-width: 22.65%; */
    min-width: 22.65%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.agent-table-header-cell-actions {
    background-color: #ffffff;
    color: rgb(103, 106, 108);
    font-weight: 700;
    font-size: 13px;
    padding: 10px;
    padding-left: 8px;
    vertical-align: top;
    text-align: left;
    border-right: 0.5px solid #dcdcdc;
    border-bottom: 2px solid #dcdcdc;
    cursor: pointer;
    min-width: 22.65%;
    /* max-width: 22.65%; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.agent-table-header-cell-small {
    background-color: #ffffff;
    color: rgb(103, 106, 108);
    font-weight: 700;
    font-size: 13px;
    padding: 10px;
    padding-left: 8px;
    vertical-align: top;
    text-align: left;
    border-right: 0.5px solid #dcdcdc;
    border-bottom: 2px solid #dcdcdc;
    cursor: pointer;
    /* max-width: 8%; */
    min-width: 8%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.agent-table-header-cell:last-child {
    border-right: none;
}

.agent-table-header-cell-actions:last-child {
    border-right: none;
}

.agent-table-header-title {
    max-width: 45px;
}

.agent-table-header-icons {
    color: #aeafb1;
    vertical-align: bottom;
    margin-top: auto;
}

.agent-table-up-down-arrow-icons {
    width: 7px;
    color: #e1e1e2;
}

.agent-table-directional-arrow-icon {
    width: 16px;
    height: 16px;
    margin-left: 5px;
    stroke: #888;
}

.agent-table-row {
    background-color: #ffffff;
    font-size: 14px;
    border-bottom: 0.5px solid #dcdcdc;
    display: flex;
    flex-direction: row;
    /* background: purple; */
}

.agent-table-row:nth-of-type(odd) {
    background-color: #f8f8f8;
}

/* .agent-table-row:hover {
    background-color: #f1f1f1;
} */

.agent-table-custom-td {
    padding: 10px;
    padding-left: 8px;
    vertical-align: top;
    text-align: left;
    border: none;
    border-right: 0.5px solid #dcdcdc;
    color: rgb(103, 106, 108);
    font-weight: 400;
    font-size: 13px;
    /* max-width: 22.65%; */
    min-width: 22.65%;
    /* height: 100%; */
    min-height: 60px;
}

.agent-table-custom-td-actions {
    /* background-color: orange; */
    padding: 10px;
    padding-left: 8px;
    vertical-align: top;
    text-align: left;
    border: none;
    border-right: none;
    color: rgb(103, 106, 108);
    font-weight: 400;
    font-size: 13px;
    min-width: 22.65%;
    /* max-width: 22.65%; */
}

.agent-table-custom-td-grey {
    padding: 10px;
    padding-left: 8px;
    vertical-align: top;
    text-align: left;
    border: none;
    border-right: 0.5px solid #dcdcdc;
    color: rgb(103, 106, 108);
    font-weight: 400;
    font-size: 13px;
    /* max-width: 8%; */
    min-width: 8%;
    min-height: 60px;
}

.agent-table-custom-td-small {
    padding: 10px;
    padding-left: 8px;
    vertical-align: top;
    text-align: left;
    border: none;
    border-right: 0.5px solid #dcdcdc;
    color: rgb(103, 106, 108);
    font-weight: 400;
    font-size: 13px;
    /* max-width: 8%; */
    min-width: 8%;
}

.agent-table-custom-td:first-child {
    border-left: none;
}

.agent-table-custom-td:last-child {
    border-right: none;
}

/* .agent-table-custom-td-actions:last-child {
    border-right: none;
} */

.agent-table-edit-button,
.agent-table-pwd-button,
.agent-table-blk-button {
    background: linear-gradient(145deg, white, #e0e0e0);
    color: #333;
    text-transform: none;
    font-size: 14px;
    margin-right: 8px;
    border: 1px solid lightgrey;
    border-radius: 4px;
    padding: 6px 12px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease, box-shadow 0.3s ease;
    min-width: 60px;
    min-height: 36px;
}

.agent-table-edit-button {
    background: linear-gradient(145deg, white, white);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}

.agent-table-edit-button:hover {
    background: linear-gradient(145deg, #e0e0e0, #f0f0f0);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.agent-table-pwd-button {
    background: linear-gradient(145deg, #3963af, #294c8f);
    color: #fff;
}

.agent-table-pwd-button:hover {
    background: linear-gradient(145deg, #294c8f, #3963af);
}

.agent-table-blk-button {
    background: linear-gradient(145deg, #fd5c63, #fd5c63);
    color: #fff;
}

.agent-table-blk-button:hover {
    background: linear-gradient(145deg, #b71c1c, #d32f2f);
}

.agent-table-edit-button-small {
    background: linear-gradient(145deg, white, white);
    color: #333;
    text-transform: none;
    font-size: 12px;
    margin-right: 8px;
    border: 1px solid lightgrey;
    border-radius: 4px;
    padding: 3px 9px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease, box-shadow 0.3s ease;
    min-width: 26px;
    min-height: 24px;
}

.agent-table-csv-button {
    background: linear-gradient(145deg, white, #e0e0e0);
    color: #333;
    text-transform: none;
    font-size: 14px;
    margin-right: 8px;
    border: 1px solid lightgrey;
    border-radius: 4px;
    padding: 6px 12px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease, box-shadow 0.3s ease;
    min-width: 60px;
    min-height: 36px;
}

.agent-table-csv-button:hover {
    background: linear-gradient(145deg, #e0e0e0, #f0f0f0);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    color: #333;
}

.agent-table-csv-button:focus {
    color: #333;
}

.agent-table-pdf-button {
    background: linear-gradient(145deg, white, #e0e0e0);
    color: #333;
    text-transform: none;
    font-size: 14px;
    margin-right: 8px;
    border: 1px solid lightgrey;
    border-radius: 4px;
    padding: 0px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease, box-shadow 0.3s ease;
    min-width: 50px;
    min-height: 35px;
}

.agent-table-pdf-button:hover {
    background: linear-gradient(145deg, #e0e0e0, #f0f0f0);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    color: #333;
}

.agent-table-button-icon {
    margin-right: 4px;
}

.agent-table-pagination {
    text-align: center;
    padding: 10px;
}

.agent-table-pagination-button {
    background-color: #f0f0f0;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    color: #333;
    cursor: pointer;
    font-size: 14px;
    margin: 0 4px;
    padding: 6px 12px;
    transition: background 0.3s ease, border-color 0.3s ease;
}

.agent-table-pagination-button.active {
    background-color: #3963af;
    color: #fff;
    border-color: #3963af;
}

.agent-table-pagination-button:hover {
    background-color: #e0e0e0;
}

.agent-table-pagination-button:disabled {
    cursor: not-allowed;
    background-color: #f0f0f0;
    border-color: #dcdcdc;
}

.agent-display-name-link {
    height: 100%;
    padding-left: 0px;
    text-decoration: none;
    color: #337ab7;
    font-size: 13px;
    font-weight: 700;
    text-align: left;
}

.agent-display-name-link:hover {
    color: #22527c;
}

.agent-table-pagination-container {
    margin-top: 20px;
    width: 100%;
}

@media (orientation: portrait) {

    .agent-table-root {
        padding: 10px;
        padding-top: 15px;
        padding-bottom: 50px;
    }

    .agent-table-container {
        /* max-width: 100%; */
        min-width: 740px;
        margin-left: 10px;
    }

    .agent-table-header-cell-grey {
        /* max-width: 10%; */
        min-width: 70px;
    }

    .agent-table-header-cell {
        /* max-width: 15%; */
        min-width: 140px;
    }

    .agent-table-header-cell-actions {
        min-width: 89px;
        /* max-width: 15%; */
    }

    .agent-table-header-cell-small {
        /* max-width: 15%; */
        min-width: 100px;
    }

    .agent-table-custom-td {
        /* max-width: 15%; */
        min-width: 140px;
    }

    .agent-table-custom-td-actions {
        min-width: 87px;
        border-right: 0.5px solid #dcdcdc;
        /* max-width: 15%; */
    }

    .agent-table-custom-td-grey {
        /* max-width: 10%; */
        min-width: 70px;
    }

    .agent-table-custom-td-small {
        /* max-width: 15%; */
        min-width: 100px;
    }

    .agent-table-edit-button,
    .agent-table-pwd-button,
    .agent-table-blk-button {
        font-size: 12px;
        margin-bottom: 2px;
        margin-right: 8px;
        padding: 6px 8px;
    }

    .agent-table-edit-button-small {
        font-size: 12px;
        margin-bottom: 2px;
        margin-right: 8px;
        padding: 6px 8px;
    }

    .agent-table-pagination-container {
        padding-left: 10px;
    }
}