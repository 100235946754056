.live-match-container {
  display: flex;
  flex-direction: row;
  /* height: 100vh; */
  overflow: hidden;
  /* padding-top: 15px; */
  padding-left: 1px;
}

.live-match-container-nav-open {
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
  width: 100%;
}

.live-match-left-column {
  width: 40%;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  padding-right: 2px;
}

.live-match-right-column {
  flex-grow: 1;
  overflow-y: auto;
  padding: 0px;
  height: auto;
}

.live-streaming {
  background-color: #000;
  height: 30%;
}

.live-match-screen-scoreboard-container {
  width: 100%;
  height: 80%;
  margin-top: 0px;
  background-color: #041E42;
  box-shadow: rgb(6 22 33 / 30%) 0px 1px 2px;
  border-radius: 5px;
  padding: 2px;
  margin-right: 0px;
}

.live-match-screen-stream-container {
  width: 100%;
  height: 70%;
  margin-top: 0px;
  background-color: #041E42;
  box-shadow: rgb(6 22 33 / 30%) 0px 1px 2px;
  border-radius: 5px;
  padding: 2px;
  margin-right: 0px;
}

/* Mobile adjustments */
@media (orientation: portrait) {
  .live-match-container {
    flex-direction: column;
    padding: 0px;
  }

  .live-match-container-nav-open {
    flex-direction: column;
    padding: 0px;
    margin-left: 0px;
  }

  .live-match-left-column {
    width: 100%;
    height: auto;
    padding-bottom: 1px;
    padding-left: 0%;
  }

  .live-match-right-column {
    width: 100%;
    height: auto;
    margin-left: 0px;
  }

  .live-match-screen-scoreboard-container {
    width: 100.3%;
    margin-top: 0px;
    padding: 0px;
    margin-right: 0px;
    border-radius: 0px;
    margin-bottom: 0px;
  }

  .live-match-screen-stream-container {
    width: 100%;
    height: 200px;
    padding: 0px;
    margin-right: 0px;
    border-radius: 0px;
  }
}