/* StatementGenerator.css */
.center-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .date-input-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .input-label {
    flex: 1;
    margin-right: 10px;
    font-weight: bold;
    font-size: 18px;
  }
  
  .date-input {
    flex: 2;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    /* Add any other styling to match the DatePicker here */
  }

  .center-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    padding: 20px;
  }

  .paper-container {
    max-width: 100%; /* Set the maximum width to 100% */
    overflow-x: auto; /* Add horizontal scrolling for smaller screens */
  }
  
  