.centered-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: 400px;
    background-color: rgba(240, 240, 240, 0.7);
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .moving-image {

    position: absolute;
    width: 110px;
    bottom: 10%;
    left: 10%;
    animation: moveImage 5s ease-in-out forwards, hoverImage 2s ease-in-out 5s infinite;
}
    
  
  @keyframes moveImage {
    0% {
      bottom: 10%;
      left: 10%;
    }
    10% {
        bottom: 15%;
        left: 10%;

      }
    100% {
      bottom: 80%;
      left: 70%;

    }
   
  }
  
  @keyframes hoverImage {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-50%);
    }
  }

 

  .trail-dot {
    position: absolute;
    width: 4px;
    height: 4px;
    background-color: red;
    border-radius: 50%;
    opacity: 0.7; /* Adjust opacity as desired */
  }