.expandable-table {
  margin-top: 10px;
}

.expandable-table-header {
  background-color: #f0f0f0;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expandable-table-content {
  margin-top: 10px;
}

.table.expandable-table-table {
  width: 100%;
  border-collapse: collapse;
}

.table.expandable-table-table th,
.table.expandable-table-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table.expandable-table-table th {
  background-color: #f4f4f4;
}
