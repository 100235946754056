/* Table styles */
.user-info-table {
    width: 96%;
    border-collapse: collapse;
    margin-left: auto;
    margin-right: auto;
}

.user-info-table td {
    padding: 5px;
    text-align: left;
    border: 1px solid #ddd;
    color: #676a6c;
    font-weight: 400;
    font-size: 14px;
    /* Border around each cell */
}

/* Left column (fields) */
.field-column {
    background-color: #f8f8f8;
    /* Light grey for the fields column */
    color: #676a6c;
    font-weight: 700;
    font-size: 14px;
    width: 35%;
    /* Adjust the width of the field column */
}

/* Right column (values) */
.value-column {
    background-color: white;
    /* White for the values column */
}

/* Alternating row background for value column */
/* .user-info-table tr:nth-child(even) .value-column {
    background-color: #f2f2f2;
} */

/* Responsive design for smaller screens */
@media screen and (max-width: 768px) {
    .user-info-table td {
        padding: 8px;
    }
}