.org-chart {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.org-chart-node {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 16px;
}

.node-content {
  display: flex;
  align-items: center;
  padding: 5px 15px 5px 8px;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 280px;
  background-color: #204b99; /* Background for all nodes */
  color: white;
}

.node-icon {
  font-size: 0.9rem;
  margin-right: 8px;
  background-color: #f3f3f4; /* Background for abbreviation */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2px;
  border-radius: 50%;
  color: #676a6c;
  font-weight: 400;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0; /* Ensures icon stays fixed in size */
}

.node-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right; /* Right-align node text (title + description) */
  flex-grow: 1; /* Make node-text fill the remaining space */
}

.node-title {
  font-size: 0.8rem;
  margin-bottom: 5px;
}

.node-description {
  font-size: 0.7rem;
  color: #e0e0e0;
}

/* Downward Arrow */
.org-chart-node:not(:first-child):after {
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid #204b99; /* Downward arrow matching node background */
  transform: translateX(-50%);
}

/* Mobile styles */
@media (max-width: 768px) {
  .org-chart {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    flex-direction: column;
  }

  .node-content {
    padding: 4px 24px 4px 10px;
    width: 100%; /* Node width 100% on mobile */
    font-size: 1rem;
  }

  .node-icon {
    font-size: 1.1rem; /* Smaller abbreviation size */
    width: 42px;
    height: 42px;
  }

  .node-title {
    font-size: 1rem; /* Smaller title font size */
  }

  .node-description {
    font-size: 0.8rem; /* Smaller description font size */
  }
}
