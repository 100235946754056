.settings-root {
    padding-top: 50px;
}

.settings-button-grid-container {
    padding: 10px;
    padding-top: 5px;
    justify-content: center;
}

.settings-button-row-container {
    display: flex;
    flex-direction: row;
}

.settings-buttons-container {
    display: flex;
    flex-direction: row;
}

@media (orientation: portrait) {
    .settings-root {
        border: 1px 0 0 black;
        justify-content: center;
    }

    .settings-button-grid-container {
        display: flex;
        flex-direction: column;
        padding: 0px;
        padding-top: 0px;
    }

    .settings-button-row-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}