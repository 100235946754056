.bet-history-table-root {
    background: white;
    overflow-x: auto;
    padding: 20px;
    padding-bottom: 50px;
    font-family: "Roboto Condensed", sans-serif;
    scrollbar-width: none;
}

.bet-history-table-container {
    width: 100%;
    /* border: 1px solid #ddd; */
    border-radius: 0px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 16px 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding-top: 0px;
    padding-bottom: 40px;
    font-family: "Roboto Condensed", sans-serif;
    scrollbar-width: none;
}

.bet-history-table-custom {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    min-width: 800px;
    scrollbar-width: none;
}

.bet-history-table-header {
    display: flex;
    flex-direction: row;
}

.bet-history-table-header-cell-grey {
    background-color: #f4f4f5;
    color: rgb(103, 106, 108);
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700;
    font-size: 13px;
    padding: 8px;
    padding-left: 8px;
    vertical-align: top;
    text-align: left;
    border: 1px solid #dcdcdc;
    cursor: pointer;
}

.bet-history-table-header-cell {
    background-color: #ffffff;
    color: rgb(103, 106, 108);
    font-weight: 700;
    font-size: 13px;
    padding: 10px;
    padding-left: 8px;
    vertical-align: top;
    text-align: left;
    border-right: 0.5px solid #dcdcdc;
    border-bottom: 2px solid #dcdcdc;
    cursor: pointer;
    max-width: 22.65%;
    min-width: 22.65%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.bet-history-table-header-cell-actions {
    /* background-color: green; */
    color: rgb(103, 106, 108);
    font-weight: 700;
    font-size: 13px;
    padding: 10px;
    padding-left: 8px;
    vertical-align: top;
    text-align: left;
    border-right: 0.5px solid #dcdcdc;
    border-bottom: 2px solid #dcdcdc;
    cursor: pointer;
    min-width: 22.65%;
    max-width: 22.65%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.bet-history-table-header-cell-small {
    background-color: #ffffff;
    color: rgb(103, 106, 108);
    font-weight: 700;
    font-size: 13px;
    padding: 10px;
    padding-left: 8px;
    vertical-align: top;
    text-align: left;
    border-right: 0.5px solid #dcdcdc;
    border-bottom: 2px solid #dcdcdc;
    cursor: pointer;
    max-width: 8%;
    min-width: 8%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.bet-history-table-header-cell:last-child {
    border-right: none;
}

.bet-history-table-header-cell-actions:last-child {
    border-right: none;
}

.bet-history-table-header-title {
    max-width: 45px;
}

.bet-history-table-header-icons {
    color: #aeafb1;
    vertical-align: bottom;
    margin-top: auto;
}

.bet-history-table-up-down-arrow-icons {
    width: 7px;
    color: #e1e1e2;
}

.bet-history-table-directional-arrow-icon {
    width: 16px;
    height: 16px;
    margin-left: 5px;
    stroke: #888;
}

.bet-history-table-row {
    font-size: 14px;
}

.bet-history-table-row:nth-of-type(odd) {
    background-color: #f9f9f9;
}

/* .bet-history-table-row:hover {
    background-color: #f1f1f1;
} */

.bet-history-table-custom-td {
    padding: 8px;
    vertical-align: top;
    text-align: left;
    border: 1px solid #dcdcdc;
    border-top: none;
    font-family: "Roboto Condensed", sans-serif;
    color: rgb(103, 106, 108);
    font-weight: 400;
    font-size: 13px;
    font-style: normal;
}

.bet-history-table-custom-td-link {
    padding: 10px;
    padding-left: 8px;
    vertical-align: top;
    text-align: left;
    border: 1px solid #dcdcdc;
    border-top: none;
    text-decoration: none;
    color: #0070BB;
    font-size: 13px;
    font-weight: 600;
}

.bet-history-table-custom-td-link:hover {
    color: #22527c;
}

.bet-history-table-custom-td-actions {
    /* background-color: orange; */
    padding: 10px;
    padding-left: 8px;
    vertical-align: top;
    text-align: left;
    border: none;
    border-right: 0.5px solid #dcdcdc;
    color: rgb(103, 106, 108);
    font-weight: 400;
    font-size: 13px;
    min-width: 22.65%;
    max-width: 22.65%;
}

.bet-history-table-custom-td-grey {
    padding: 10px;
    padding-left: 8px;
    vertical-align: top;
    text-align: left;
    border: none;
    border-right: 0.5px solid #dcdcdc;
    color: rgb(103, 106, 108);
    font-weight: 400;
    font-size: 13px;
    max-width: 8%;
    min-width: 8%;
    min-height: 60px;
}

.bet-history-table-custom-td-small {
    padding: 10px;
    padding-left: 8px;
    vertical-align: top;
    text-align: left;
    border: none;
    border-right: 0.5px solid #dcdcdc;
    color: rgb(103, 106, 108);
    font-weight: 400;
    font-size: 13px;
    max-width: 8%;
    min-width: 8%;
}

/* .bet-history-table-custom-td:first-child {
    border-left: none;
}

.bet-history-table-custom-td:last-child {
    border-right: none;
} */

.bet-history-table-custom-td-actions:last-child {
    border-right: none;
}

.bet-history-table-edit-button,
.bet-history-table-pwd-button,
.bet-history-table-blk-button {
    background: linear-gradient(145deg, white, #e0e0e0);
    color: #333;
    text-transform: none;
    font-size: 14px;
    margin-right: 8px;
    border: 1px solid lightgrey;
    border-radius: 4px;
    padding: 6px 12px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease, box-shadow 0.3s ease;
    min-width: 60px;
    min-height: 36px;
}

.bet-history-table-edit-button:hover {
    background: linear-gradient(145deg, #e0e0e0, #f0f0f0);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.bet-history-table-pwd-button {
    background: linear-gradient(145deg, #3963af, #294c8f);
    color: #fff;
}

.bet-history-table-pwd-button:hover {
    background: linear-gradient(145deg, #294c8f, #3963af);
}

.bet-history-table-blk-button {
    background: linear-gradient(145deg, #d32f2f, #b71c1c);
    color: #fff;
}

.bet-history-table-blk-button:hover {
    background: linear-gradient(145deg, #b71c1c, #d32f2f);
}

.bet-history-table-button-icon {
    margin-right: 4px;
}

.bet-history-table-pagination {
    text-align: center;
    padding: 10px;
}

.bet-history-table-pagination-button {
    background-color: #e2e2e2;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    color: #333;
    cursor: pointer;
    font-size: 14px;
    margin: 0 4px;
    padding: 6px 12px;
    transition: background 0.3s ease, border-color 0.3s ease;
    height: 32px;
}

.bet-history-table-pagination-button.active {
    background-color: #3963af;
    color: white;
    border-color: #3963af;
}

.bet-history-table-pagination-button:hover {
    background-color: #e0e0e0;
    color: #676a6c;
    font-weight: 800;
    border-color: #3963af;
}

.bet-history-table-pagination-button:disabled {
    cursor: not-allowed;
    background-color: #f0f0f0;
    border-color: #dcdcdc;
}

.bet-history-display-name-link {
    height: 10px;
    padding-left: 0px;
    text-decoration: none;
    color: #0070BB;
    font-size: 13px;
    font-weight: 400;
}

.bet-history-display-name-link:hover {
    color: #22527c;
}

.bet-history-table-pagination-container {
    margin-top: 20px;
    width: 100%;
}

.match-table-pagination-button {
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    color: #007bff;
    padding: 5px 10px;
    margin: 0 2px;
    cursor: pointer;
    font-size: 14px;
    user-select: none;
}

.match-table-pagination-button.active {
    background-color: #007bff;
    color: #fff;
}

.match-table-pagination-button:hover {
    background-color: #e0e0e0;
}

.match-table-pagination-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.match-table-pagination-ellipsis {
    border: none;
    background: none;
    color: #007bff;
    font-size: 14px;
    padding: 5px 10px;
}

.summary-table-row {
    font-size: 14px;
}

.summary-table-header-cell-grey {
    background-color: #f5f5f5;
    color: rgb(103, 106, 108);
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 600;
    font-size: 13px;
    padding: 5px;
    padding-left: 8px;
    vertical-align: top;
    text-align: left;
    border: 1px solid #dcdcdc;
    cursor: pointer;
}

.summary-table-custom-td {
    padding: 5px;
    padding-left: 8px;
    vertical-align: top;
    text-align: left;
    border: 1px solid #dcdcdc;
    border-top: none;
    color: rgb(103, 106, 108);
    font-weight: 550;
    font-size: 13px;
    background: white;
}

@media (orientation: portrait) {

    .bet-history-table-root {
        padding: 10px;
        padding-top: 15px;
        padding-bottom: 50px;
    }

    .bet-history-table-container {
        margin-left: 10px;
    }

    .bet-history-table-custom {
        min-width: 900px;
    }

    .bet-history-table-header-cell-grey {
        max-width: 10%;
        min-width: 10%;
    }

    .bet-history-table-header-cell {
        max-width: 15%;
        min-width: 15%;
    }

    .bet-history-table-header-cell-actions {
        min-width: 15%;
        max-width: 15%;
    }

    .bet-history-table-header-cell-small {
        max-width: 15%;
        min-width: 15%;
    }

    .bet-history-table-custom-td {
        max-width: 15%;
        min-width: 15%;
    }

    .bet-history-table-custom-td-actions {
        min-width: 15%;
        max-width: 15%;
    }

    .bet-history-table-custom-td-grey {
        max-width: 10%;
        min-width: 10%;
    }

    .bet-history-table-custom-td-small {
        max-width: 15%;
        min-width: 15%;
    }

    .bet-history-table-edit-button,
    .bet-history-table-pwd-button,
    .bet-history-table-blk-button {
        font-size: 12px;
        margin-bottom: 2px;
        margin-right: 8px;
        padding: 6px 8px;
    }

    .bet-history-table-pagination-container {
        padding-left: 10px;
    }

    .summary-table-container {
        width: 100%;
        margin-left: 10px;
    }

    .summary-table-custom {
        width: 100%;
        min-width: 0px;
    }

    .summary-table-row {
        font-size: 14px;
        width: 100%;
    }

    .summary-table-header-cell-grey {
        background-color: #f5f5f5;
        color: rgb(103, 106, 108);
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 600;
        font-size: 13px;
        padding: 5px;
        padding-left: 8px;
        vertical-align: top;
        text-align: left;
        border: 1px solid #dcdcdc;
        cursor: pointer;
        max-width: 30%;
    }

    .summary-table-custom-td {
        padding: 5px;
        padding-left: 8px;
        vertical-align: top;
        text-align: left;
        border: 1px solid #dcdcdc;
        border-top: none;
        font-family: "Roboto Condensed", sans-serif;
        color: rgb(103, 106, 108);
        font-weight: 550;
        font-size: 13px;
        background: white;
        max-width: 30%;
    }
}