.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: 'Roboto Condensed';
  src: url('./assets/fonts/RobotoCondensed-Regular.ttf') format('truetype');
  font-style: normal;
}

.top-div {
  height: 50%;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto Condensed', sans-serif;
}

.active-user-content-footer-holder {
  height: 100%;
  padding-top: 125px;
}

.active-user-content-holder {
  height: 100%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.app-logo-humburger-icon-container {
  display: flex;
  flex-direction: row;
}

.app-logo {
  height: 42px;
  cursor: pointer;
  vertical-align: middle;
  margin: 5px;
  -webkit-tap-highlight-color: transparent;
}

.nav-path-holder {
  color: rgb(103, 106, 108);
  width: 100%;
  height: fit-content;
  display: block;
  direction: column;
  justify-content: center;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 0px;
  margin-top: 2px;
  /* margin-bottom: 25px; */
  background-color: white;
  border: 1px solid #e8e8e8;
  text-align: left;
}

.nav-path-holder-child {
  text-align: left;
  padding: 8px;
}

.nav-path-current-page {
  font-size: 24px;
}

.nav-path {
  font-size: 13px;
  padding-bottom: "10px";
}

.cric-footer-container {
  position: fixed;
  z-index: 999;
  bottom: 0;
  width: 95%;
  pointer-events: none;
}

.cric-footer-holder {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
  background-color: white;
  border: 1px solid #e8e8e8;
  font-size: 12px;
  margin-top: 100px;
}

.div-match-winner-radio-button {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  font-size: 16px;
}

.div-label-form {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  font-size: 16px;
  color: #3963af;
  /* Cric247 changes earlier -> color: #48aaad; */
}

.cric-div-label-form {
  width: 30%;
  text-align: right;
  font-size: 13px;
  color: #676a6c;
  font-weight: 700;
  padding-right: 10px;
  padding-top: 6px;
  /* Cric247 changes earlier -> color: #48aaad; */
}

.cric-div-input-form {
  width: 80%;
  font-size: 13px;
  font-family: "Roboto Condensed", sans-serif;
  color: #6c6f67;
  font-weight: 400;
  padding-right: 10px;
  /* Cric247 changes earlier -> color: #48aaad; */
}

.cric-div-lable-static-info {
  width: 80%;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  color: #676a6c;
  font-weight: 500;
  text-align: left;
  padding-top: 5px;
  /* Cric247 changes earlier -> color: #48aaad; */
}

.cric-div-gp-label-form {
  width: 100%;
  text-align: right;
  font-size: 14px;
  color: #3963af;
  padding-right: 10px;
  padding-bottom: 10px;
  text-align: left;
  /* Cric247 changes earlier -> color: #48aaad; */
}

.cric-div-textarea-form {
  width: 100%;
  font-size: 14px;
  text-align: left;
  /* Cric247 changes earlier -> color: #48aaad; */
}

.global-property-text-area {
  width: 100%;
}

.register-top-container {
  width: 100%;
  background-image: url('/src/assets/login_background.jpg');
  height: 100%;
  min-height: 665px;
  background-size: cover;
  background-repeat: no-repeat;
}

.cric-register-top-container {
  width: 99.2%;
  /* background-image: url('/src/assets/login_background.jpg'); */
  height: 100%;
  background: white;
  margin-left: 5px;
  /* background-size: cover;
  background-repeat: no-repeat; */
}

.cric-agent-actions-top-container {
  width: 100%;
  /* background-image: url('/src/assets/login_background.jpg'); */
  height: 100%;
  margin-top: -30px;
  /* background-size: cover;
  background-repeat: no-repeat; */
}

.cric-agent-actions-notification-popup-container {
  width: 100%;
  /* background-image: url('/src/assets/login_background.jpg'); */
  height: 100%;
  border: 1px solid black;
  border-radius: none;
  /* background-size: cover;
  background-repeat: no-repeat; */
}

.agent-notification-popup-header {
  background: #1daab5;
  width: 100%;
  color: white;
  font-size: 14;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500;
  height: 50px;
  padding-top: 12px;
}

.agent-notification-popup-body {
  width: 100%;
  color: #676a6c;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 80px;
  text-align: left;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #676a6c;
}

.agent-notification-popup-separator {
  width: 100%;
  background: #e2e2e2;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.agent-notification-popup-close-button {
  width: 100;
  align-items: center;
  height: 54px;
}

.cric-agent-downline-balance-popup-container {
  width: 100%;
  /* background-image: url('/src/assets/login_background.jpg'); */
  height: 100%;
  border: 1px solid black;
  border-radius: none;
  padding: 5%;
  border-radius: 10px;
  /* background-size: cover;
  background-repeat: no-repeat; */
}

.cric-agent-downline-balance-popup-header {
  background: #1daab5;
  width: 100%;
  color: white;
  font-size: 14;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500;
  height: 50px;
  padding-top: 12px;
}

.cric-agent-downline-balance-popup-body {
  width: 100%;
  color: #676a6c;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 30px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  text-align: center;
  min-height: 60%;
}

.downline-balance-popup-title {
  color: rgb(87, 87, 87);
}

.downline-balance-popup-value {
  color: red;
  margin-top: 20px;
  margin-bottom: 30px;
}

.cric-agent-downline-balance-popup-separator {
  width: 100%;
  background: #e2e2e2;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.cric-agent-downline-balance-popup-close-button {
  width: 100;
  align-items: center;
  height: 50px;
}

.board-buttons-nav-bar-dark-small-downline-balance-close {
  background: #0071c5;
  max-width: 18%;
  height: 40px;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 3em;
  font-weight: 500;
  color: white;
  border-radius: 2px;
  box-shadow: none;
}

.user-exposure-popup-container {
  width: 100%;
  /* background-image: url('/src/assets/login_background.jpg'); */
  height: 100%;
  border: 1px solid black;
  border-radius: none;
  /* background-size: cover;
  background-repeat: no-repeat; */
}

.user-exposure-popup-header {
  background: #1daab5;
  width: 100%;
  color: white;
  font-size: 14;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500;
  height: 50px;
  padding-top: 12px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.user-exposure-popup-body {
  width: 100%;
  color: #676a6c;
  font-size: 13;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500;
  padding-top: 2px;
  min-height: 80px;
  text-align: left;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #676a6c;
}

.user-exposure-popup-separator {
  width: 100%;
  background: #e2e2e2;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.user-exposure-popup-close-button {
  width: 100;
  align-items: center;
  height: 50px;
}

.x-button {
  background: transparent;
  padding: 0px;
  font-weight: bold;
  cursor: pointer;
  padding-bottom: 15px;
}

.admin-global-porperty-popup-container {
  width: 100%;
  /* background-image: url('/src/assets/login_background.jpg'); */
  height: 100%;
  border: 1px solid black;
  border-radius: none;
  /* background-size: cover;
  background-repeat: no-repeat; */
}

.admin-global-porperty-popup-header {
  background: #204b99;
  width: 100%;
  color: white;
  font-size: 14;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500;
  height: 50px;
  padding-top: 12px;
}

.admin-global-porperty-popup-body {
  width: 100%;
  color: #676a6c;
  font-size: 13;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 80px;
  text-align: left;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #676a6c;
}

.admin-global-porperty-popup-separator {
  width: 100%;
  background: #e2e2e2;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.admin-global-porperty-popup-close-button {
  width: 100;
  align-items: center;
  height: 50px;
}

.agent-deposit-withdraw-popup-container {
  width: 100%;
  /* background-image: url('/src/assets/login_background.jpg'); */
  height: 100%;
  border: 1px solid black;
  border-radius: none;
  /* background-size: cover;
  background-repeat: no-repeat; */
}

.agent-deposit-withdraw-popup-header {
  background: #204b99;
  width: 100%;
  color: white;
  font-size: 14;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500;
  height: 50px;
  padding-top: 12px;
}

.agent-deposit-withdraw-popup-body {
  width: 100%;
  color: #676a6c;
  font-size: 13;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 80px;
  text-align: left;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #676a6c;
}

.agent-deposit-withdraw-popup-separator {
  width: 100%;
  background: #e2e2e2;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.agent-deposit-withdraw-popup-close-button {
  width: 100;
  align-items: center;
  height: 50px;
}

.ladder-popup-container {
  width: 99.8%;
  /* background-image: url('/src/assets/login_background.jpg'); */
  height: 100%;
  border: 1px solid black;
  border-radius: none;
  /* background-size: cover;
  background-repeat: no-repeat; */
}

.ladder-popup-header {
  background: #f3f3f3;
  width: 100%;
  color: black;
  font-size: 14;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
}

.ladder-popup-body {
  width: 100%;
  color: #676a6c;
  font-size: 13;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500;
  padding-top: 2px;
  min-height: 80px;
  text-align: left;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #676a6c;
}

.ladder-popup-separator {
  width: 100%;
  background: #e2e2e2;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.ladder-popup-close-button {
  width: 100;
  align-items: center;
  height: 50px;
}

.ladder-popup-x-button {
  background: transparent;
  padding: 0px;
  font-weight: bold;
  cursor: pointer;
  color: red;
  width: 20px;
  padding: 0px;
  margin-right: 5px;
}

.ladder-popup-x-button:hover {
  background: transparent;
}

.cric-change-password-top-container {
  background: white;
  width: 99%;
  /* background-image: url('/src/assets/login_background.jpg'); */
  height: 100%;
  margin-left: 5px;
  /* min-height: 665px; */
  /* background-size: cover;
  background-repeat: no-repeat; */
}

.register-container {
  padding-left: 1px;
  padding-right: 1px;
  padding-bottom: 0px;
}

.register-header {
  text-align: center;
  background-color: #48aaad;
  color: white;
  font-size: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-bottom: 10px;
  width: 100%;
  border-radius: 5px 5px 0px 0px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3), inset 3px 3px 5px rgba(0, 0, 0, 0.3);
}

.cric-register-header {
  text-align: center;
  background-color: #3963af;
  color: white;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 3px;
  margin-bottom: 0px;
  width: 100%;
  border-radius: 1px 1px 0px 0px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3), inset 3px 3px 5px rgba(0, 0, 0, 0.3);
  height: 50px;
  vertical-align: middle;
}

.cric-deposit-withdraw-header {
  text-align: center;
  background-color: #3963af;
  color: white;
  font-size: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  width: 100.7%;
  margin-left: -2px;
  /* border-radius: 5px 5px 0px 0px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3), inset 3px 3px 5px rgba(0, 0, 0, 0.3); */
}

.cric-alert-popup-header {
  text-align: center;
  background-color: #40B5AD;
  color: white;
  font-size: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-bottom: 10px;
  border-radius: 1px 1px 0px 0px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3), inset 1px 1px 2px rgba(0, 0, 0, 0.3);
  border: solid 1px #f8f8f8;
}

.card-container {
  align-content: center;
  max-width: 600px !important;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.cric-card-container {
  align-content: center;
  padding-top: 20px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 0px;
  padding-bottom: 30px;
}

.card {
  margin-left: auto;
  margin-right: auto;
  max-width: 600px !important;
  box-shadow: 0px 0px 2px rgba(154, 154, 154, 154);
  /* padding: 20px 20px;
  background-color: #f7f7f7;
  margin-top: 30px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3); */
}

.cric-card {
  margin-left: auto;
  margin-right: auto;
  /* box-shadow: 0px 0px 2px rgba(154, 154, 154, 154); */
  /* padding: 20px 20px;
  background-color: #f7f7f7;
  margin-top: 30px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3); */
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.game-board-card-container {
  display: flex;
}

.card-game-board-default {
  position: relative;
  font-size: 20px;
  color: #FFFFFF;
  text-align: center;
  -webkit-transition-duration: 0.4s;
  /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  /* background-color: var(--green-color); */
  max-width: 240px !important;
  height: 180px;
  padding: 40px 20px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}

.card-game-board-default:hover,
.card-game-board-default-submit:hover {
  opacity: 0.8;
}

.card-game-board-default:after {
  content: "";
  background: #FFFFFF;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s
}

.card-game-board-default:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s
}

.choice-default {
  background-color: #04AA6D;
  width: 240px;
  margin-right: 5px;
}

/* Green */
.choice-best-2 {
  background-color: #2196F3;
  width: 210px;
  margin-right: 5px;
}

/* Blue */
.choice-best-3 {
  background-color: #ff9800;
  width: 180px;
  margin-right: 5px;
}

/* Orange */
.choice-best-4 {
  background-color: #f44336;
  width: 150px;
  margin-right: 5px;
}

/* Red */
.choice-best-5 {
  background-color: #f44336;
  width: 120px;
  margin-right: 5px;
}

/* Yellow */
.choice-best-6 {
  background-color: #964B00;
  width: 110px;
  margin-right: 5px;
}

/* Brown */
.choice-best-7 {
  background-color: #FF69B4;
  width: 100px;
  margin-right: 5px;
}

/* Pink */
.choice-best-8 {
  background-color: #A020F0;
  width: 90px;
  margin-right: 5px;
}

/* Purple */
.choice-best-9 {
  background-color: #50C878;
  width: 80px;
  margin-right: 5px;
}

/* Emrald */
.choice-best-0 {
  background-color: var(--dark);
  color: var(--off-white);
  width: 70px;
  margin-right: 5px;
}

/* Dark */

.board-buttons-container {
  display: flex;
}

.board-buttons {
  position: relative;
  font-size: 16px;
  color: #FFFFFF;
  text-align: center;
  -webkit-transition-duration: 0.4s;
  /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  width: 155px;
  height: 40px;
  /* background-color: var(--green-color); */
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
}

.form-buttons-cancel-submit {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 320px;
}

.form-buttons-cancel-submit-edit {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 318px;
}

.form-update-dotted-separator {
  border-top: 1px dashed #f2f2f2;
  margin: 20px 0;
}

.cric-board-buttons {
  position: relative;
  font-size: 16px;
  color: #FFFFFF;
  text-align: center;
  -webkit-transition-duration: 0.4s;
  /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  width: 155px;
  height: 46px;
  /* background-color: var(--green-color); */
  -moz-border-radius: 1px;
  -webkit-border-radius: 1px;
  -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}

.board-buttons:hover,
.board-buttons-submit:hover {
  opacity: 0.8;
}

.board-buttons:after {
  content: "";
  background: #FFFFFF;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s
}

.board-buttons:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s
}

.board-buttons:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.board-buttons-nav-bar-dark-large {
  background-color: #204b99;
  width: 30%;
  margin-right: 5px;
  font-size: 15;
  font-weight: 700;
  color: white;
  height: 40px;
}

.cric-board-agent-mathces-dashboard-buttons-large {
  font-family: "Roboto Condensed", sans-serif;
  background-color: #204b99;
  width: 50%;
  margin-right: 5px;
  color: white;
}

.cric-board-admin-settings-buttons-large {
  background-color: #204b99;
  width: 100%;
  margin-right: 5px;
}

.board-buttons-nav-bar-dark-medium {
  background-color: #204b99;
  width: 170px;
  margin-right: 5px;
}

/* Nav Dark; */
.board-buttons-nav-bar-dark {
  background-color: #204b99;
  margin-right: 5px;
}

/* Nav Dark; */
.board-buttons-nav-bar-dark-small {
  background-color: #204b99;
  width: 120px;
  margin-right: 5px;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: white;
}

.board-buttons-nav-bar-dark-small2 {
  background-color: #204b99;
  width: 120px;
  height: 40px;
  margin-right: 5px;
}

.board-buttons-nav-bar-dark-small2-live-report {
  background-color: #204b99;
  width: 90px;
  height: 34px;
  margin-right: 5px;
  font-family: "Roboto Condensed", sans-serif;
}

/* Nav Dark */
.board-buttons-nav-bar-dark-small-thin {
  background-color: #204b99;
  width: 116px;
  height: 30px;
  margin-right: 5px;
}

.board-buttons-nav-bar-dark-small-save-changes {
  background-color: #204b99;
  width: 100px;
  margin-right: 5px;
  font-weight: 700;
  font-size: 14px;
  box-shadow: none;
  border: 1px solid sky;
  border-radius: 4px;
}

.board-buttons-nav-bar-dark-smaller-save-changes {
  background-color: #204b99;
  width: 70px;
  margin-right: 5px;
  font-weight: 700;
  font-size: 14px;
  box-shadow: none;
  border: 1px solid sky;
  border-radius: 4px;
  height: 36px;
}

/* Nav Dark */
.board-buttons-nav-bar-dark-smaller {
  background-color: #204b99;
  width: 80px;
  margin-right: 5px;
}

.board-buttons-nav-bar-dark-smaller-white {
  background-color: white;
  width: 66px;
  margin-right: 5px;
  color: #676a6c;
  font-weight: 700;
  font-size: 14px;
  box-shadow: none;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
}

.board-buttons-nav-bar-dark-smaller-black {
  background-color: black;
  width: 66px;
  margin-right: 5px;
  color: #f8f8f8;
  font-weight: 700;
  font-size: 14px;
  box-shadow: none;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  height: 36px;
}

/* Nav Dark */
.board-buttons-nav-bar-dark-smallest {
  background-color: var(--nav-bar-dark);
  width: 90px;
  height: 26px;
  margin-right: 50px;
  padding-left: 0px;
  padding-right: 0px;
  font-size: 14px;
}

/* Nav Dark */
.board-buttons-nav-bar-dark-smaller-thin {
  background-color: var(--nav-bar-dark);
  width: 110px;
  height: 30px;
  margin-right: 5px;
}

.board-buttons-nav-bar-dark-smaller-thin-add-agent {
  background-color: #40B5AD;
  width: 110px;
  height: 30px;
  margin-right: 5px;
  border: 1px solid white;
}

.board-buttons-nav-bar-dark-small-agent-notification-close {
  background: #1fabb5;
  width: 110px;
  height: 40px;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14;
  font-weight: 500;
  color: white;
  border-radius: 2px;
  box-shadow: none;
}

.board-buttons-nav-bar-dark-smaller-thin2 {
  background-color: var(--nav-bar-dark);
  width: 90px;
  height: 25px;
  margin-right: 5px;
  font-size: 10px;
}

/* Nav Dark */
.board-buttons-nav-bar-dark-smallest {
  background-color: var(--nav-bar-dark);
  width: 60px;
  margin-right: 5px;
}

/* Nav Dark */
.board-buttons-nav-bar-dark-tiny {
  background-color: var(--nav-bar-dark);
  width: 50px;
  height: 25px;
  margin-right: 1px;
  text-align: center;
}

/* Nav Dark */
.board-buttons-nav-bar-dark-tiny-trans {
  background-color: transparent;
  width: 34px;
  height: 35px;
  margin-right: 0px
}

/* Nav Dark */
.board-buttons-nav-bar-dark-red {
  background-color: red;
  width: 155px;
  margin-right: 5px;
}

/* Red */

.board-buttons-green-large {
  background-color: #04AA6D;
  width: 200px;
  margin-right: 5px;
}

/* Green */
.board-buttons-green {
  background-color: #04AA6D;
  margin-right: 5px;
}

/* Green */
.board-buttons-green-small {
  background-color: #04AA6D;
  width: 120px;
  margin-right: 5px;
}

/* Green */
.board-buttons-green-smaller {
  background-color: #04AA6D;
  width: 80px;
  margin-right: 5px;
}

/* Green */
.board-buttons-green-smallest {
  background-color: #04AA6D;
  width: 60px;
  margin-right: 5px;
}

/* Green */
.board-buttons-blue {
  background-color: #2196F3;
  width: 280px;
  margin-right: 5px;
}

/* Blue */
.board-buttons-orange {
  background-color: #ff9800;
  width: 240px;
  margin-right: 5px;
}

/* Orange */
.board-buttons-red {
  background-color: #f44336;
  width: 200px;
  margin-right: 5px;
}

/* Red */
.board-buttons-dark {
  background-color: var(--dark);
  color: var(--off-white);
  width: 160px;
  margin-right: 5px;
}

/* Gray */

.expired-notice {
  text-align: center;
  padding: 2rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  margin: 0.5rem;
}

.expired-notice>span {
  font-size: 2.5rem;
  font-weight: bold;
  color: red;
}

.expired-notice>p {
  font-size: 1.5rem;
}

.show-counter {
  padding: 0.5rem;
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 0.5rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  text-decoration: none;
  color: #000;
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.75rem 0 0.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  color: #ff0000;
}

.show-counter .countdown>p {
  margin: 0;
}

.show-counter .countdown>span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
}

.icon-font-size {
  transform: scale(1.8);
}

.rcorners2 {
  border-radius: 10px;
  border: 2px solid #73AD21;
  width: 36px;
  height: 36px;
}

.navbar-nav .nav-item .nav-link {
  color: white;
}

.nav-link {
  background-color: #395793;
  border-radius: 10px;
  margin: 2px;
}

body {
  /*background-image: url('assets/bg.jpg'); /* Replace with the path to your background image */
  background-size: contain;
  background-repeat: repeat;
  background-position: center;
  background-blend-mode: lighten;
  font-family: 'Roboto Condensed', sans-serif;
}

.div-note {
  display: flex;
  flex-direction: row;
  font-size: 13px;
  color: #676a6c;
  font-weight: 400;
}

.label-note {
  font-size: 12px;
  font-weight: bold;
}

.div-note-1 {
  padding-left: 5px;
  font-size: 12px;
}

.div-note-2 {
  padding-left: 3px;
  font-size: 12px;
  font-weight: bold;
}

.toggle-button-container {
  margin-top: 5px;
  font-size: 20px;
}

.cric-toggle-button-container {
  margin-top: 10px;
  font-size: 20px;
}

.hr-solid {
  margin-top: 20px;
  border-top: 3px solid #bbb;
}

.noti-top-container {
  width: 100%;
}

.noti-container {
  display: flex;
  direction: row;
  justify-content: center;
}

.noti-controls-container {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
}

.cric-form-label-input-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

.cric-form-label-input-container-add {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

.cric-form-label-textarea-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

input {
  outline: none;
  padding: 12px;
  border-radius: 3px;
  border: 1px solid black;
}

::-webkit-input-placeholder {
  /* Chrome */
  color: #a9a9a9;
  font-size: 14px;
  font-weight: 400;
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}

:focus::-webkit-input-placeholder {
  opacity: 0.5;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #a9a9a9;
  font-size: 14px;
  font-weight: 400;
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}

:focus:-ms-input-placeholder {
  opacity: 0.5;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #a9a9a9;
  font-size: 14px;
  font-weight: 400;
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}

:focus::-moz-placeholder {
  opacity: 0.5;
}

:-moz-placeholder {
  /* Firefox 4 - 18 */
  color: #a9a9a9;
  font-size: 14px;
  font-weight: 400;
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}

:focus:-moz-placeholder {
  opacity: 0.5;
}

input::placeholder,
textarea::placeholder {
  color: #999999 !important;
  /* Light gray to mimic disabled text */
  opacity: 1 !important;
  /* Ensure full opacity */
}

input:disabled::placeholder,
textarea:disabled::placeholder {
  color: #999999 !important;
  /* Disabled placeholder color */
}


.mini-nav-bar-profile-block {
  color: #fff1e6;
  text-align: left;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  /* font-family: "Lucida Console", "Courier New", monospace; */
  font-family: "Roboto Condensed", sans-serif;
}

.mini-nav-bar-submenu-div {
  background: #353e43;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 13px;
  font-weight: 700;
  color: #f5f5f5;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3), inset 6px 6px 10px rgba(0, 0, 0, 0.3);
  transition: height 2s ease;
}

.breadcrumbs-previous-paths {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: #676a6c;
  text-decoration: none;
  pointer-events: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  align-content: center;
}

/* .breadcrumbs-previous-paths-ellipsis {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: #676a6c;
  text-decoration: none;
  pointer-events: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
} */

.clock-icon {
  width: 12px;
  vertical-align: middle;
  padding-bottom: 2px;
  margin-right: 2px;
}

@media (orientation: portrait) {

  .nav-path-holder {
    width: 100%;
    height: fit-content;
    display: block;
    direction: column;
    justify-content: center;
    margin-top: 2px;
    margin-bottom: 2px;
    background-color: white;
    border: 1px solid #e8e8e8;
  }

  .nav-path-holder-child {
    text-align: left;
    padding: 8px;
  }

  .nav-path-current-page {
    font-size: 30px;
  }

  .nav-path {
    font-size: 14px;
  }

  .register-top-container {
    /* background-image: url(''); */
    height: 100%;
    min-height: 778px;
    padding-top: 10px;
  }

  .cric-register-top-container {
    /* background-image: url(''); */
    height: 100%;
  }

  .cric-change-password-top-container {
    width: 97%;
    /* background-image: url('/src/assets/login_background.jpg'); */
    height: 100%;
    padding-bottom: 120px;
    /* min-height: 665px; */
    /* background-size: cover;
    background-repeat: no-repeat; */
  }

  .register-container {
    padding-top: 0px;
  }

  /* .cric-footer-container {
    top: 10%;
  } */

  .cric-footer-holder {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;
    background-color: white;
    border: 1px solid #e8e8e8;
    font-size: 12px;
    text-align: bottom;
  }

  .cric-form-label-input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
  }

  .cric-form-label-input-container-add {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
  }

  .cric-div-label-form {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 0px;
    /* Cric247 changes earlier -> color: #48aaad; */
  }

  .cric-div-input-form {
    width: 100%;
    font-size: 14px;
    /* Cric247 changes earlier -> color: #48aaad; */
  }

  .board-buttons-nav-bar-dark-large {
    background-color: #204b99;
    width: 97%;
    margin-bottom: 5px;
  }

  .cric-board-agent-mathces-dashboard-buttons-large {
    background-color: #204b99;
    width: 50%;
    margin-bottom: 5px;
    margin-left: 2px;
    margin-right: 2px;
  }

  .cric-board-admin-settings-buttons-large {
    width: 50%;
    min-height: 70px;
    margin-bottom: 5px;
  }

  .cric-toggle-button-container {
    margin-top: 8px;
    font-size: 20px;
  }

  .cric-deposit-withdraw-header {
    width: 101%;
  }

  .cric-card-container {
    padding-top: 10px;
  }

  .cric-footer-container {
    width: fit-content;
    margin-top: 20px;
  }

  .active-user-content-footer-holder {
    padding-top: 135px;
  }

  .form-buttons-cancel-submit {
    margin-top: 20px;
    padding-left: 0px;
  }

  .form-buttons-cancel-submit-edit {
    margin-top: 20px;
    padding-left: 0px;
  }

  .form-update-dotted-separator {
    border-top: 1px dashed #f2f2f2;
    margin: 20px 0;
  }

  .board-buttons-nav-bar-dark-small-downline-balance-close {
    max-width: 25%;
    font-size: 1.3em;
  }

  .breadcrumbs-previous-paths {
    /* display: inline-block;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
    vertical-align: middle;
  }
}