.user-info-root {
    display: flex;
    flex-direction: row;
    background: white;
    padding-top: 20px;
    padding-left: 3px;
    padding-right: 5px;
}

.hierarchy-container {
    width: 30%;
    margin-bottom: 20px;
    margin-right: 2px;
}

.user-details-container {
    width: 70%;
    margin-bottom: 20px;
    margin-left: 2px;
}

@media screen and (max-width: 768px) {
    .user-info-root {
        flex-direction: column;
    }

    .hierarchy-container {
        width: 100%;
        margin-right: 0px;
    }

    .user-details-container {
        width: 100%;
        margin-left: 0px;
    }
}