
.label-welcome {
    font-size: 18px;
    font-weight: bold;
    color: #71797E;
}

/* Media query for smaller screens */
@media (max-width: 767px) {
    .label-welcome {
        font-size: 16px;
    }
}